import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {FormattedMessage, useIntl} from "react-intl";
import {MDBRow, MDBCol} from "mdbreact";
import classNames from "classnames";

import Details from "..";
import InputFieldIntl from "../../../../components/InputFieldIntl";
import DisplayOnlyField from "../../../../components/DisplayOnlyField";
import CardNumberField from '../../../../components/CardNumberField';
import CvvField from '../../../../components/CvvField';
import MaskedNumberField from "../../../MaskedNumberField";

import {TextFormatters} from '../../../../utils/validation/standard-validations';
import { getSupportedDebitCards, mapCardTypeToCss, getSupportedCardsCss, identifyCardType, getSupportedCardsLabel  } from '../../../../utils/payment-utils';
import {CardTypeSelect, checkShowCardTypeSelect} from "../CardTypeSelect";
import {onTab} from "../../../../utils/utils";
import {useValidator} from "../../../../utils/validation";
import {isCobrandCardTypeSelectEnabled} from "../../../../utils/config-utils";
const trim = (value) => value?.replace(/\D/g,'')

export default function DCDetails({
    isModal, country, pmCategory, paymentMethod, submitAction, handleCancel,
    tokenizableOnly, extraFields, hideNickField, showDebitNetwork, variationSize, controlled
}) {
    const { user: { selectedCountry: merchantCountry } } = useSelector(s => s.auth);
    const { merchants } = useSelector(s => s.config);
    const { merchant } = useSelector(s => s.config?.sessionSelection ?? {})
    const validator = useValidator()
    const intl = useIntl()
    const cobrandCardTypeSelectEnabled = isCobrandCardTypeSelectEnabled(merchant)

    const [supportedCards, setSupportedCards] = useState([]);

    const [state, setState] = useState({});
    const identifySupportedCardType = (cardNum, cat) => identifyCardType (cardNum, cat, null, supportedCards, cobrandCardTypeSelectEnabled);

    useEffect(() => {
        const pm = paymentMethod;
        if (!pm) return;
        setState({
            cardNickName: pm.cardNickName,
            cardNumber: pm.accountNumber,
            cardType: identifySupportedCardType(pm.accountNumber, pmCategory) || pm.methodType,
            cardHolderName: pm.cardHolderName,
            expirationDate: pm.ccExpiry && `${pm.ccExpiry.month}/${pm.ccExpiry.year}`,
            cvv: pm.cvvNumber,
            line1: pm.address?.line1,
            city: pm.address?.city,
            zipCode: pm.address?.zipCode
        });
    }, []);

    useEffect(() => {
        if (!controlled) return;
        validatePM();
    }, [JSON.stringify(state)]);

    useEffect(() => {
        setSupportedCards(getSupportedDebitCards (merchantCountry, {tokenizableOnly}) (merchants));
    }, [merchantCountry, merchants]);

    useEffect(() => {
        if (country === 'HK') {
            setState(s => ({
                ...s,
                zipCode: ''
            }));
        }
    }, [country]);

    const handleInput = e => {
        const {name, value} = e.target;
        setState({...state, [name]: value});
    };

    const handleCardNumberInput = e => {
        const value = e.target.value.replace(/\s+/g, ""); // Trim spaces for API
        setState({
            ...state,
            [e.target.name]: value,
            cardType: identifySupportedCardType(value, pmCategory),
            forcedCardType: undefined
        });
    };

    const validatePM = () => {
        const {
            cvv, cardNumber, cardHolderName, expirationDate,
            cardNickName, line1, city, zipCode, cardType, forcedCardType
        } = state;

        const data = {
            methodType: forcedCardType ?? cardType,
            accountNumber: cardNumber,
            cvvNumber: cvv,
            cardHolderName,
            cardNickName,
            address: {country, zipCode},
            ccExpiry: {
                year: expirationDate?.slice(-2),
                month: expirationDate?.slice(0, 2)
            },
            categoryCode: pmCategory
        };
        if (extraFields) Object.assign(data.address, {line1, city});
        if (paymentMethod) data.id = paymentMethod.id;

        return submitAction?.(data);
    }

    const {cvv, cardNumber, cardHolderName, expirationDate, cardNickName, line1, city, zipCode, cardType, forcedCardType, matchingCards} = state;

    const form = <React.Fragment>
        <MDBRow>
            {showDebitNetwork &&
                <MDBCol size="12">
                    <div className="pm-logo-row debit-logos mb-3">
                        <span className="cc-dc-title label">
                            <FormattedMessage id='payment-method.debit-network'/>
                        </span>
                        <img className="logo-star" src="images/star.png" alt="STAR"/>
                        <img className="logo-nyce" src="images/nyce.png" alt="NYCE"/>
                        <img className="logo-pulse" src="images/pulse.png" alt="Pulse"/>
                    </div>
                </MDBCol>
            }

            {controlled || !paymentMethod
                ? <MDBCol md={variationSize ? "12" : "6"}>
                    <CardNumberField
                        key={"cardNumber"}
                        name={"cardNumber"}
                        id={"cardNumber"}
                        className={classNames("DC-cardNumber",{"card-number-card-type-select-showing":forcedCardType!==undefined})}
                        formClassName={classNames({"card-number-card-type-select-showing":forcedCardType!==undefined})}
                        value={cardNumber}
                        label={<FormattedMessage id="payment-method.credit-card.card-number"/>}
                        hint={"payment-method.credit-card.card-number.placeholder"}
                        containerClass={"has-custom-graphic"}
                        onChange={handleCardNumberInput}
                        rightSideSpanClass={classNames("pm-highlight pm-icon", mapCardTypeToCss(forcedCardType !== undefined ? forcedCardType : cardType))}
                        acceptedCards={getSupportedCardsCss(supportedCards)}
                        acceptedCardLabels={getSupportedCardsLabel(supportedCards).sort()}
                        forcedCardType={forcedCardType}
                        onBlur={(e)=>{
                            //in some situations we need to show the card type selector (when multiple card types match)
                            if(cobrandCardTypeSelectEnabled) checkShowCardTypeSelect(e,validator,forcedCardType,cardNumber,pmCategory,supportedCards,setState)
                        }}
                        onKeyDown={onTab((e)=>{
                            // if we tab out of the card number field, and we're in a situation where showing the card type selector should happen,
                            // we need to prevent tabbing to the expiration date element before we show the card type selector
                            if(cobrandCardTypeSelectEnabled) checkShowCardTypeSelect(e,validator,forcedCardType,cardNumber,pmCategory,supportedCards,setState)
                        })}
                        required
                        validations={[
                            ['required', "field.required"],
                            ['validCreditCard', "field.validCreditCard", supportedCards]
                        ]}
                    />
                    {cobrandCardTypeSelectEnabled && forcedCardType !== undefined &&
                        <CardTypeSelect
                            id={'cardType'}
                            name={'cardType'}
                            cardType={forcedCardType}
                            cardTypeList={matchingCards}
                            aria-label={intl.formatMessage({id:"payment-method.select-card-network"})}
                            label={<FormattedMessage id="payment-method.card-network" />}
                            placeholder={<FormattedMessage id="payment-method.select-card-network"/>}
                            onChange={(select)=>{setState((s)=>({
                                ...s, forcedCardType:select.value
                            }))}}
                            autoFocus
                            required
                            validations={[
                                ['required', 'field.required']
                            ]}
                        />
                    }
                </MDBCol>
                : <MDBCol md={variationSize ? "12" : "6"}>
                    <DisplayOnlyField
                        label={<FormattedMessage id="payment-method.credit-card.card-number"/>}
                        value={cardNumber}
                        wrapperMode={true}
                    />
                </MDBCol>
            }

            <MDBCol md={variationSize ? "6" : "4"}>
                <MaskedNumberField
                    format={TextFormatters.creditCardExpiry}
                    key="expirationDate" name="expirationDate" id="expirationDate"
                    label={<FormattedMessage id="payment-method.credit-card.expiration-date"/>}
                    value={expirationDate}
                    onChange={handleInput}
                    hint="payment-method.credit-card.expiration-date.placeholder"
                    maxLength={5}
                    required
                    validations={[
                        ['required', "field.required"],
                        ['validExpiryDate', "field.validExpiryDate"]
                    ]}
                />
            </MDBCol>

            <MDBCol md={variationSize ? "6" : "2"}>
                <CvvField name="cvv" id="cvv"
                    value={cvv}
                    onChange={handleInput}
                    length={cardType === "AMEX_DEBIT" ? '4' : '3'}
                />
            </MDBCol>
            
            <MDBCol md={variationSize ? "12" : "6"}>
                <InputFieldIntl key="cardHolderName" name="cardHolderName" id="cardHolderName"
                    label={<FormattedMessage id="payment-method.credit-card.card-holder-name"/>}
                    value={cardHolderName}
                    onChange={handleInput}
                    hint="payment-method.credit-card.card-holder-name.placeholder"
                    maxLength={256}
                    required
                    validations={[
                        ['required', "field.required"],
                        ['alphaNumericAndPunctuation', "field.alphaNumericAndPunctuation"],
                        ['notCreditCard', "field.notCreditCard"]
                    ]}
                />
            </MDBCol>

            {!hideNickField &&
                <MDBCol md={variationSize ? "12" : "6"}>
                    <InputFieldIntl key="cardNickName" name="cardNickName" id="cardNickName"
                        label={<FormattedMessage id="payment-method.card-nickname"/>}
                        value={cardNickName}
                        onChange={handleInput}
                        onBlur={(e)=>{
                            e.target.value = e.target.value.trim()
                            handleInput(e)
                        }}
                        hint="payment-method.card-nickname"
                        maxLength={32}
                        validations={[
                            (value)=>((trim(value)?.includes(trim(cardNumber))) ? "field.notCreditCard" : ""),
                            ['notCreditCard', "field.notCreditCard"]
                        ]}
                    />
                </MDBCol>
            }

            {extraFields &&
                <MDBCol md={variationSize ? "12" : "6"}>
                    <InputFieldIntl key="line1" name="line1" id="line1"
                        label={<FormattedMessage id="payment-method.billing-address.line1"/>}
                        value={line1}
                        onChange={handleInput}
                        hint={"payment-method.billing-address.line1.placeholder"}
                        maxLength={32}
                        required
                        validations={[
                            ['required', "field.required"],
                            ['notCreditCard', "field.notCreditCard"],
                            ['alphaNumericAndPunctuation', "field.alphaNumericAndPunctuation"]
                        ]}
                    />
                </MDBCol>
            }

            {extraFields &&
                <MDBCol md={variationSize ? "8" : "6"}>
                    <InputFieldIntl key="city" name="city" id="city"
                        label={<FormattedMessage id="payment-method.billing-address.city"/>}
                        value={city}
                        onChange={handleInput}
                        hint={"payment-method.billing-address.city.placeholder"}
                        maxLength={32}
                        required
                        validations={[
                            ['required', "field.required"],
                            ['notCreditCard', "field.notCreditCard"],
                            ['alphaAndPunctuation', "field.alphaAndPunctuation"]
                        ]}
                    />
                </MDBCol>
            }


            {country !=='HK' &&
                <MDBCol md={variationSize ? "4" : "3"}>
                    <InputFieldIntl key="zipCode" name="zipCode" id="zipCode"
                        label={<FormattedMessage id="payment-method.billing-address.zip-code"/>}
                        value={zipCode}
                        onChange={handleInput}
                        hint="payment-method.billing-address.zip-code.placeholder"
                        maxLength={32}
                        required
                        validations={[
                            ['required', "field.required"],
                            ['zipCode', "field.zipCode", {selectedCountry: country}]
                        ]}
                    />
                </MDBCol>
            }
        </MDBRow>
    </React.Fragment>;

    if (controlled) return form;

    return <Details
        form={form}
        isModal={isModal}
        onSubmit={validatePM}
        onCancel={handleCancel}
        extraFooterCssClass="mt-3"
    />;
}
