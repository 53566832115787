import React, { useEffect, useReducer }                        from "react";
import {useDispatch, useSelector}                              from "react-redux";
import {MDBModal, MDBModalBody, MDBModalHeader,MDBRow, MDBCol} from "mdbreact";
import {FormattedMessage, useIntl}                             from "react-intl";
import * as R                                                  from 'ramda';

import SelectFieldIntl                                         from '../../../SelectFieldIntl';
import ReactPortalModal                                        from "../../../ReactPortalModal";
import CCDetails                                               from "../../PaymentMethodDetails/CCDetails";
import DCDetails                                               from "../../PaymentMethodDetails/DCDetails";
import DDDetails                                               from "../../PaymentMethodDetails/DDDetails";
import SepaDetails                                             from "../../PaymentMethodDetails/SepaDetails";
import BacsDetails                                             from "../../PaymentMethodDetails/BacsDetails/BacsDetails";
import PayPalAccountDetails                                    from "../../PaymentMethodDetails/PayPalAccountDetails";
import PlaceholderPmDetails                                    from "../../PaymentMethodDetails/PlaceholderPmDetails";

import {
    getNonCreditPaymentCategories, formatPMDesc
    , getMerchantPaymentCategories
    , pmCatRequiresAddress, formatPMCategoryDesc
    , pmCatImmuatableAddress, isPMCategoryOnlySavableToWalletInPaymentFlow
} from '../../../../utils/payment-utils';
import {generateCountryList
    , sortCountryList}                                      from "../../../../utils/utils";
import {getAllCountryCodes}                                    from "../../../../utils/ups-utils";
import {useValidator}                                          from "../../../../utils/validation";
import {addWalletPm, updateWalletPm}                           from '../../../../api/wallet-api';
import Error                                                   from '../../../../components/Error';
import PageError                                               from "../../../PageError";
import { clearErrors }                                         from "../../../../actions/error-action";
import Spinner                                                 from '../../../Spinner';
import { countryData }                                         from "../../../../constants/country-data";
import DisplayOnlyField                                        from "../../../../components/DisplayOnlyField";
import SCBDetails from "../../PaymentMethodDetails/SCBDetails";

const paymentMethodModalInitialState = {
    country: '',
    paymentMethodCategory: 'CC',
    error: '',
    categories: [],
    submitting: false,
    paymentMethod: null
};

function paymentMethodModalReducer (state, action) {
    switch (action.type) {
        case 'SET_COUNTRY_CAT': return {
            ...state,
            country: action.country,
            paymentMethodCategory: action.category ?? state.paymentMethodCategory,
            error: ''
        }
        case 'SET_CATEGORIES': return {
            ...state,
            categories: action.categories
        }
        case 'SET_PAY_CAT': return {
            ...state,
            paymentMethodCategory: action.category ?? R.head(state.categories)?.value,
            error: ''
        }
        case 'SET_ERROR': return {
            ...state,
            error: action.error,
            submitting: false
        }
        case 'SUBMIT': return {
            ...state,
            submitting: true,
            paymentMethod: action.paymentMethod
        }
        default: return state;
    }
}

const getPaymethodForm = (payCategory, props) => {
    switch (payCategory) {
        case 'CC': return <CCDetails {...props} pmCategory="CC"/>;
        case 'DC': return <DCDetails {...props} pmCategory="DC"/>;
        case 'DD': return <DDDetails {...props} pmCategory="DD"/>;
        case 'SEPA': return <SepaDetails {...props} pmCategory="SEPA"/>;
        case 'BACS': return <BacsDetails {...props} pmCategory="BACS" />;
        case 'PAYPAL_ACCOUNT': return  <PayPalAccountDetails {...props} pmCategory="PAYPAL_ACCOUNT"/>;
        case 'SCB': return <SCBDetails {...props} pmCategory="SCB"/>;
        default: return <PlaceholderPmDetails {...props}/>;
    }
}

const getDisplayPmCategoryOptions = (intl, creditAllowed) => R.compose
( R.map(value => ({value, label: formatPMCategoryDesc (intl) (value)}))
    , categories => categories.filter((catg) => !isPMCategoryOnlySavableToWalletInPaymentFlow(catg))
    , categories => !creditAllowed ? getNonCreditPaymentCategories (categories) : categories
    , getMerchantPaymentCategories()
);

const getPaymentMethodId = R.path(['paymentMethod', 'id']);

export default function AddPaymentMethodModal ({paymentMethod, isOpen, toggleModal, backdrop,
                                                   isModal, creditAllowed = true, walletSuccess}) {
    const [paymentMethodModalState, dispatch] = useReducer(paymentMethodModalReducer, paymentMethodModalInitialState);

    const {sessionSelection, countries} = useSelector(s => s.config);
    const {merchant} = sessionSelection ?? {}
    const {countryCode: selectedCountry} = merchant;
    //const countries = getAllCountryCodes();

    const reduxDispatch = useDispatch();

    const intl = useIntl();
    const validator = useValidator();

    useEffect (() => {
        dispatch({ type: 'SET_CATEGORIES'
            , categories: getDisplayPmCategoryOptions (intl, creditAllowed) (merchant)
        });
    }, [merchant, intl.locale]);

    useEffect (() => {
        dispatch({type: 'SET_COUNTRY_CAT',
            country: paymentMethod?.address?.country ?? selectedCountry,
            category: paymentMethod?.categoryCode ?? paymentMethodModalState.categories?.[0]?.value
        });
    }, [paymentMethodModalState.categories, paymentMethod, selectedCountry]);

    useEffect (() => {
        if (isOpen) {
            dispatch({type: 'SET_PAY_CAT', category: paymentMethod?.categoryCode});
        }
        reduxDispatch(clearErrors());
    }, [isOpen]);

    useEffect(() => {
        if (paymentMethodModalState.submitting) {
            if (paymentMethod) {
                updateWalletPm({ id: getPaymentMethodId(paymentMethodModalState)
                    , paymentMethod: paymentMethodModalState.paymentMethod
                })
                    .then(handleSuccess)
                    .catch(dispatchApiError);
            } else {
                addWalletPm({ id: getPaymentMethodId(paymentMethodModalState)
                    , paymentMethod: paymentMethodModalState.paymentMethod
                })
                    .then(handleSuccess)
                    .catch(dispatchApiError);
            }
        }

    }, [paymentMethodModalState.submitting]);

    const dispatchApiError = ({errorCode}) => dispatch({type: 'SET_ERROR', error: errorCode});
    const clearError = () => dispatch({type: 'SET_ERROR', error: ''});

    const handleSuccess = pm => {
        if (walletSuccess) {
            walletSuccess(pm);
        }
        handleCancel();
    }

    const handleCancel = () => {
        toggleModal('addPaymentMethod');
        clearError();
    };

    const handleSubmit = paymentMethod => {
        clearError();
        let result = validator.validateAll();
        if (result.messages.length === 0) {
            dispatch({type: 'SUBMIT', paymentMethod});
        }
    }

    const formProps = {
        submitAction: handleSubmit,
        handleCancel: handleCancel,
        isModal,
        paymentMethod,
        tokenizableOnly: true,
        extraFields: true,
        country: paymentMethodModalState.country
    };

    const sortedCountriesList = sortCountryList(generateCountryList(countries, countryData, intl));

    if (isOpen) {
        const modalHeading = `payment-method.wallet.label.${paymentMethod ? "edit" : "add"}-method`;
        return (
            <ReactPortalModal isOpen={isOpen} an_label={`${paymentMethod ? "edit-payment-method" : "add-payment-method"}`}>
                <MDBModal
                    isOpen={isOpen}
                    toggle={toggleModal}
                    size={paymentMethod && paymentMethodModalState.paymentMethodCategory === "PAYPAL_ACCOUNT"
                        ? "md"
                        : "xl"
                    }
                    backdrop={backdrop}
                    disableBackdrop
                    disableFocusTrap={false}
                    labelledBy={intl.formatMessage({ id: modalHeading })}
                >
                    <Spinner isSpinning={paymentMethodModalState.submitting} />
                    <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={handleCancel}>
                        <FormattedMessage
                            id={modalHeading}
                        />
                    </MDBModalHeader>

                    <MDBModalBody>
                        {paymentMethodModalState?.error?.length > 0 &&
                        <Error errors={[paymentMethodModalState.error]} />
                        }
                        <PageError />
                        <p className="ups-note-1 mt-0">
                            *<span className="font-italic">
                                <FormattedMessage id="ups.required-field.note" />
                            </span>
                        </p>

                        <MDBRow>
                            {!paymentMethod && paymentMethodModalState.categories && (
                                <MDBCol md="6">
                                    <SelectFieldIntl
                                        name="paymentMethodCategory"
                                        id="paymentMethodCategory"
                                        value={paymentMethodModalState.paymentMethodCategory}
                                        selectOptions={paymentMethodModalState.categories}
                                        label={<FormattedMessage id="modal.payment.payment.method" />}
                                        setLabelBefore
                                        onChange={({ target: { value } }) => dispatch({ type: 'SET_PAY_CAT', category: value })}
                                    />
                                </MDBCol>
                            )}

                            {pmCatRequiresAddress(paymentMethodModalState.paymentMethodCategory) &&
                                pmCatImmuatableAddress(paymentMethodModalState.paymentMethodCategory) ? (
                                <MDBCol size="12" md="6">
                                    <DisplayOnlyField
                                        label={<FormattedMessage id="ups.enroll.country.label" />}
                                        value={intl.formatMessage({ id: `country-item.${paymentMethodModalState.country}`})}
                                        wrapperMode
                                    />
                                </MDBCol>
                                ) : pmCatRequiresAddress(paymentMethodModalState.paymentMethodCategory) ? (
                                    <MDBCol size="12" md="6">
                                    <SelectFieldIntl
                                        name="country"
                                        id="country"
                                        value={paymentMethodModalState.country}
                                        selectOptions={sortedCountriesList}
                                        label={<FormattedMessage id="ups.enroll.country.label" />}
                                        setLabelBefore
                                        onChange={({ target: { value } }) => dispatch({ type: 'SET_COUNTRY_CAT', country: value })}
                                    />
                                </MDBCol>
                                ) : <></> 
                            }
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size="12">
                                <div className="mt-2">
                                    {getPaymethodForm(paymentMethodModalState.paymentMethodCategory, formProps)}
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                </MDBModal>
            </ReactPortalModal>
        );
    } else {
        return null;
    }
}
