/*
 * Copyright (C) 2019 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on
 * Created by
 *
 */

import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import * as identityApi from '../api/identity-api';
import * as identityActions from '../actions/identity-action';
import * as actionTypes from '../constants/action-types';
import * as layoutActions from '../actions/layout-action';
import { mapRole } from "../utils/utils";
import * as errorActions from "../actions/error-action";
import * as settingActions from "../actions/setting-action";
import { getCSRTokens, setCSRTokens } from "../utils/local-storage";
import { getSessionSelection } from '../utils/config-utils';

const getAuth = (state) => state.auth;
const getIdentityPreferences = (state) => state.identity.preferences;
const getEnvProps = (state) => state.envProps;
const getMerchants = (state) => state.config.merchants;

function* checkIdentityExists (action) {
  try {
    yield put(layoutActions.showSpinner());
    const envProps = yield select(getEnvProps);
    const { apiUrlPrefix, apiToken } = envProps;
    // const currentUser = yield select(getUser);
    let { data, callback } = action;

    const { parsedBody } = yield call(identityApi.checkIdentityExists, data, apiUrlPrefix, apiToken);

    if (parsedBody) {
      if (parsedBody.errorCode) {
        yield put(errorActions.setErrors([parsedBody.errorCode]));
      } else {
        if (parsedBody.ccrTokens) {
          yield call(setCSRTokens, parsedBody.ccrTokens);
        }
        if (callback) {
          yield call(callback, parsedBody);
        }
      }
    }
  } catch (error) {
    yield put(errorActions.setErrors(['page.unexpectedError']));
  } finally {
    yield put(layoutActions.hideSpinner());
  }
}

function* getIdentityAccountList(action) {
    try {
        yield put(layoutActions.showSpinner());

        const { callback } = action;
        const { apiUrlPrefix, apiToken } = yield select(getEnvProps);

        const { user } = yield select(getAuth);

        if (user && user.id) {
            const { ok, parsedBody } = yield call(identityApi.getIdentityAccountList, user.externalId, apiUrlPrefix);

            if (ok && parsedBody) {
                yield put(identityActions.getIdentityAccountListSuccess(parsedBody.preferences));

                if (callback) {
                    yield call(callback, parsedBody);
                }
            }

        }
    } catch (error) {
        console.error(error);
        yield put(identityActions.getIdentityAccountListFailure());
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

function* savePreferences(action) {    
    const { preferences, callback } = action;

    try {
        yield put(layoutActions.showSpinner());

        const { apiUrlPrefix, apiToken } = yield select(getEnvProps);

        const { user } = yield select(getAuth);
        const identityPreferences = yield select(getIdentityPreferences);
        
        if (user && user.id) {
            const { ok, parsedBody } = yield call(identityApi.savePreferences, {
                ...identityPreferences,
                ...preferences
            }, apiUrlPrefix, apiToken);
            
            if (ok && parsedBody) {
                yield put(identityActions.saveIdentityPreferencesSuccess(parsedBody.preferences));

                if (callback) {
                    yield call(callback, {status: true});
                }
            }
        }


    } catch (error) {
        if (callback) {
            yield call(callback, {status: false, errorCode: error.errorCode});
        }
        console.error(error);
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

function* getAccessibleMerchants(action) {
    try {
        yield put(layoutActions.showSpinner());
        const envProps = yield select(getEnvProps);
        const { apiUrlPrefix, apiToken } = envProps;

        const response = yield call(identityApi.getAccessibleMerchants, apiUrlPrefix, apiToken);
        const { ok, parsedBody } = response;
        // for success, redirect to home page
        if (ok && parsedBody) {

            yield put(identityActions.getAccessibleMerchantsSuccess(parsedBody));
        }

    } catch (error) {
        if (error.status === 403) {
            yield put(errorActions.setErrors(['page.unexpectedError']));
        } else {
            yield put(errorActions.setErrors(['page.unexpectedError']));
        }
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}

function* updateUserSession(action) {    
    const { data } = action;
    const { preferences, updateCountry } = data;

    try {
        yield put(layoutActions.showSpinner());

        const { apiUrlPrefix, apiToken } = yield select(getEnvProps);

        const { user } = yield select(getAuth);
        const merchants = yield select(getMerchants);

        const identityPreferences = yield select(getIdentityPreferences);
        
        if (user && user.id) {
            const { ok, parsedBody } = yield call(identityApi.savePreferences, {
                ...identityPreferences,
                ...preferences
            }, apiUrlPrefix, apiToken);

            if (ok && parsedBody) {
                yield put(identityActions.saveIdentityPreferencesSuccess(parsedBody.preferences));
                const {lastSelectedCountryCode: country, lastSelectedPaymentType: selectedPaymentType, lastSelectedLanguageCode: locale, selectedMerchantId, tla} = preferences;
                
                if(updateCountry){
                    yield put(identityActions.setSelectedCountry({country, locale, selectedMerchantId, tla}));
                    yield put(identityActions.setSelectedPaymentType(selectedPaymentType));
                    yield put(settingActions.setLocale(locale));
                    yield put({type: 'SESSION_SELECTION_CHANGED',sessionSelection: getSessionSelection({selectedCountry: country, selectedPaymentType}, merchants)});
                    yield put(settingActions.setStoreState(true));
                }
                else{
                    yield put(identityActions.setSelectedPaymentType(selectedPaymentType));
                    yield put({type: 'SESSION_SELECTION_CHANGED',sessionSelection: getSessionSelection({selectedCountry: country, selectedPaymentType}, merchants)});
                    yield put(settingActions.setStoreState(true));
                }
            }
        }
    } catch (error) {
        yield put(settingActions.setStoreState(true));
        console.error(error);
    } finally {
        yield put(layoutActions.hideSpinner());
    }
}


export function* getAccessibleMerchantsSaga() {
    yield takeEvery(actionTypes.GET_ACCESSIBLE_MERCHANTS, getAccessibleMerchants);
}

export function* checkIdentityExistsSaga() {
    yield takeEvery(actionTypes.IDENTITY_EXISTS, checkIdentityExists);
}

export function* getIdentityAccountListSaga() {
    yield takeEvery(actionTypes.GET_IDENTITY_ACCOUNT_LIST, getIdentityAccountList);
}

export function* savePreferencesSaga() {
    yield takeEvery(actionTypes.SAVE_IDENTITY_PREFERENCES, savePreferences);
}

export function* updateUserSessionSaga() {
    yield takeEvery(actionTypes.UPDATE_USER_SESSION, updateUserSession);
}

function* getIdentityListByCompanyId(action) {
        const { callback } = action;
    try {
        yield put(layoutActions.showSpinner());
        const envProps = yield select(getEnvProps);
        const { apiUrlPrefix, apiToken } = envProps;
        const response = yield call(identityApi.getIdentityListByCompanyId, apiUrlPrefix, apiToken);
        if (response) {
            yield call(callback, response)
        } 
    } catch (error) {
        if (callback) {
            yield call(callback, []);
        }
    } finally {
        yield put(layoutActions.hideSpinner());

    }
}

export function* getIdentityListByCompanyIdSaga() {
    yield takeEvery(actionTypes.GET_IDENTITY_LIST_BY_COMPANY_ID, getIdentityListByCompanyId);
}

export default function* identitySaga() {
    yield all([
        fork(checkIdentityExistsSaga),
        fork(getIdentityAccountListSaga),
        fork(savePreferencesSaga),
        fork(getAccessibleMerchantsSaga),
        fork(updateUserSessionSaga),
        fork(getIdentityListByCompanyIdSaga)
    ]);
}