import React, {Component, useEffect} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { withRouter } from 'react-router-dom';
import Exception from '../../Exception/index'
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import {isEmpty as lodashIsEmpty, get as lodashGet} from 'lodash';
import CustomDateRangePicker from "../../../components/UPS/CustomDateRangePicker";
import CalendarInfoPanel from "../../../components/UPS/CalendarInfoPanel";

import ExportDataModal from '../../../components/UPS/Modal/ExportDataModal';
import InvoiceErrorModal from '../../../components/UPS/Modal/InvoiceErrorModal';
import DownloadAllDocumentModal from '../../../components/UPS/Modal/DownloadAllDocumentModal';
import InvoiceSearchCriteriaModal from '../../../components/UPS/Modal/InvoiceSearchCriteriaModal';
import DownloadMultipleInvoiceModal from "../../../components/UPS/Modal/UPSInvoiceDownloadMultipleModal";
import ViewAccountPaymentHistoryModal from '../../../components/UPS/Modal/ViewAccountPaymentHistoryModal';
import DisputesModal from '../../InvoiceDetails/DisputesModal';

import * as identityActions from "../../../actions/identity-action";
import * as invoiceActions from "../../../actions/invoice-action";
import * as paymentActivityActions from  "../../../actions/payment-history-action";
import * as invoiceStatuses from "../../../constants/invoice-statuses";

import {FormattedMessage, injectIntl} from 'react-intl';
import moment from 'moment';
import memoize from "memoize-one";
import { ReactComponent as PdfSvg } from '../../../assets/images/svg/pdf.svg'

import {
    formatDateRangeString,
    getSavedPreferences,
    isPayable,
    isOverpayment,
    isDisputesEnabled,
    isCreditInvoiceSupported,
    getMerchantTimeZoneId,
    checkPayableIr,
    filterInvoices,
    areInvoiceDocumentsDownloadable,
    sanitizeInvoiceFilter,
    getDownloadEligibility,
    handleInvoiceDownload,
    getInvoiceDownloadErrorMsg,
    isInvoiceXmlDownloadEligible, generateStatementList
} from "../../../utils/invoice-utils";
import {
    getDefaultInvoiceColumns, savedColumnCompatibility, formatAccountNumberFilter
} from "./InvoiceTable-utils"
import * as R from 'ramda';
import {produce} from "immer";
import {getConfigByBU, getKeyByBU, getMessageByBU, removeUrlQueryParams} from "../../../utils/ups-utils";
import {getMaxInvoices} from "../../../utils/config-utils";
import NotificationModal from "../Modal/NotificationModal";
import FilteredDataTableV3, {INITIAL_TABLE_STATE} from "../../DataTableV3/DataTableV3FilterWrapper";
import {defaultButtonProps, exportData, SORTED_DSC} from "../../DataTableV3/DataTableV3-utils";
import {calendarIcon} from "../../../assets/images/icon/datatableIcons";
import ColumnSettingsModal from "../Modal/ColumnSettingsModal";
import {getSessionFilter, setSessionFilter} from "../../../utils/utils";
import Spinner from '../../Spinner';

const isPlanRoute = R.compose
( R.ifElse
    ( R.isNil
        , R.F
        , R.test(/\/ups\/billing\/plan/)
    )
    // , R.tap(s => console.log('previous path', s))
    , R.path(['location', 'state', 'prevPath'])
);

class InvoiceTable extends Component {
    constructor(props) {
        super(props);

        const { preferences, isDrillMode,calendarOption, businessUnit, businessUnitConfigs, filters, invoice: planInvoice, sessionSelection, countryCode } = this.props;
        const {planNumber, planInvoiceNumber} = planInvoice ?? {};

        const savedSearchCriteriaType = businessUnitConfigs('invoiceTableSearchCriteria')
        const savedColumnsType = businessUnitConfigs('invoiceTableSavedColumns')
        const sessionFilter = getSessionFilter('invoiceTableSearchCriteria',businessUnit,countryCode)
        let { dateType = "1", selectedStartDate = null, selectedEndDate = null, calendarOptionType = 'AVAILABLE'} = (!isDrillMode && lodashIsEmpty(filters)) ? getSavedPreferences(sessionFilter ? {[savedSearchCriteriaType]: sessionFilter} : preferences, savedSearchCriteriaType) : {};
        let initialFilter = {
            ...(!isDrillMode ? (R.isEmpty(filters) ? (sessionFilter ?? lodashGet(preferences, savedSearchCriteriaType, {})) : filters) : {planNumber: [planNumber], planInvoiceNumber: [planInvoiceNumber]}),
            ...(businessUnit === "SCS" && {currencyCode: sessionSelection.merchant?.currencyCode}),
            businessUnit,            
            ...(filters.invoiceFilters ? filters.invoiceFilters: {}),
            ...(calendarOption ? calendarOption: {})
        }

        initialFilter.accountNumber = formatAccountNumberFilter(initialFilter.accountNumber, businessUnit)

        if (!lodashIsEmpty(calendarOption) && calendarOption.hasOwnProperty("calendarOptionType")) {
            
            dateType = calendarOption.dateType;
            calendarOptionType = calendarOption.calendarOptionType;
        }
        this.state = {
            loaded: false,
            error: '',
            data: null,
            exportDataModal: false,
            downloadMultipleInvoiceModal: false,
            downloadAllDocumentModal: false,
            filterModal: false,
            columnSettingsModal: false,
            viewAccountPaymentHistoryModal: false,
            disputesModal: false,
            showInvoiceErrorModal: false,
            notificationModalProps: {},
            showDatePickerModal: false,
            selectedStartDate, //currently selected values within calendar modal
            selectedEndDate,
            selectedCalendarOptionType: calendarOptionType,
            selectedDateType: dateType,
            calendarOptionType, //currently set calendar settings on the table
            calendarStartDate: {...selectedStartDate},
            calendarEndDate: {...selectedEndDate},
            calendarDateType: dateType,
            savedSearchCriteriaType,
            savedColumnsType,
            tableState: INITIAL_TABLE_STATE,
            invoiceList: [],
            filter: initialFilter,
            isFilterEnabled: this.isFilterEnabled(initialFilter),
            payAllSet: new Map()
        }
    }

    componentDidMount(){
        const { invoiceActions, filterPayable, invoiceType } = this.props;
        const {filter} = this.state;

        if(filterPayable){
            const filters = {
                accounts: true, 
                keyToAccessInvoices: invoiceType,
                businessUnit: this.props.selectedPaymentType,
                [invoiceType]: true
            };

            invoiceActions.getPayableInvoices(filters, (response) => {
                const payableInvoices = response[filters.keyToAccessInvoices].invoices;
                this.setInvoices(payableInvoices);
            })
        }
        else {
            invoiceActions.getInvoiceList(filter, (invoiceList)=>{            
                this.setInvoices(invoiceList);
            });
        }
    }

    setInvoices = (invoiceList) => {
        const { isDrillMode, filters : {invoiceFilters} } = this.props;

        // this case prevents the script from breaking if response to this call is the redirect url - case where session token has expired
        if(!invoiceList?.length){
            invoiceList = [];
        }
                
        if (invoiceFilters) {
            removeUrlQueryParams();
            if(invoiceList.length > 0){
                invoiceList = filterInvoices(invoiceList, invoiceFilters);
            }
        }            
        this.updateStateInvoices(invoiceList, isDrillMode);
    }

    isFilterEnabled = (filter = this.state.filter) => {
        const exists = (prop) => (!!filter?.[prop] && !R.isEmpty(filter?.[prop]))
        //calendar is not included in this consideration because it has its own filter indicator on the calendar button
        return exists('accountNumber') || exists('invoiceStatus') || exists('invoiceType') || exists('freightTerms')
    }

    toggleModal = (modalName, data) => this.setState({data, [`${modalName}Modal`]: !this.state[`${modalName}Modal`]})

    showDatePicker = () => {
        if (this.state.calendarOptionType === 'CUSTOM') {
            this.setState({ showDatePickerModal: !this.state.showDatePickerModal, selectedCalendarOptionType: this.state.calendarOptionType, selectedStartDate: moment(this.state.calendarStartDate), selectedEndDate:moment(this.state.calendarEndDate) });
        } else {
            this.setState({ showDatePickerModal: !this.state.showDatePickerModal, selectedCalendarOptionType: this.state.calendarOptionType, selectedStartDate: null, selectedEndDate:null  });
        }
    }

    getDateRangeString(){
        const {intl} = this.props;
        const {calendarOptionType, calendarStartDate, calendarEndDate} = this.state;
        return formatDateRangeString(intl, calendarOptionType, calendarStartDate ? moment(calendarStartDate) : '', calendarEndDate ? moment(calendarEndDate) : '')
    }

    handleCalendarSearch = (calendarState) => {
        const { showDatePickerModal, filter, selectedStartDate, selectedEndDate, selectedCalendarOptionType, savedSearchCriteriaType, selectedDateType } = this.state;
        const { identityActions, isDrillMode, businessUnit, countryCode } = this.props;
        const { saveSearchCriteria } = calendarState;

        if (selectedCalendarOptionType === 'CUSTOM' && !(selectedStartDate && selectedEndDate)) return;
        const newFilter = {
            ...filter,
            calendarOptionType: selectedCalendarOptionType,
            [selectedDateType === "1" ? 'invoiceDate' : 'dueDate']: selectedStartDate ? {
                dateBegin: moment(selectedStartDate).format('YYYY-MM-DD') ,
                dateEnd: moment(selectedEndDate).format('YYYY-MM-DD')
            } : null,
            [selectedDateType !== "1" ? 'invoiceDate' : 'dueDate']: null,
            dateType: selectedDateType,
            ...(filter.filterType === "EMAIL" ? {filterType: ""} : {})
        }
        this.setState({
            filter: newFilter,
            calendarStartDate: selectedStartDate,
            calendarEndDate: selectedEndDate,
            calendarOptionType: selectedCalendarOptionType,
            calendarDateType: selectedDateType,
            isFilterEnabled: this.isFilterEnabled(newFilter)
        }, () => {
            if(!isDrillMode) setSessionFilter('invoiceTableSearchCriteria', sanitizeInvoiceFilter(newFilter, isDrillMode), businessUnit, countryCode)
            if (saveSearchCriteria) identityActions.saveIdentityPreferences({[savedSearchCriteriaType]: sanitizeInvoiceFilter(newFilter, isDrillMode)});
            this.doInvoiceSearch();
        });
        if (showDatePickerModal) this.showDatePicker();
    }

    runFilter = (newFilter, isClearFilter) => {
        const {businessUnit, countryCode, isDrillMode} = this.props;
        const fullNewFilter = {...newFilter,  filterType: ''}
        if(!isDrillMode) setSessionFilter('invoiceTableSearchCriteria', fullNewFilter, businessUnit, countryCode)
        this.setState(produce(newState=>{
                newState.filter = fullNewFilter
                newState.isFilterEnabled = this.isFilterEnabled(newFilter)
                if(isClearFilter) newState.tableState.searchQuery = ''
            }),
    ()=>this.doInvoiceSearch()
        )
    }

    doInvoiceSearch = (filter = this.state.filter) => {
        const { invoiceActions } = this.props;
        invoiceActions.getInvoiceList({...filter}, (invoices) => this.updateStateInvoices(invoices));
    }
    
    clearFilter = () => this.runFilter({
        ...this.state.filter,
        accountNumber: '',
        invoiceStatus: '',
        invoiceType: '',
        freightTerms: '',
        invoiceFilters: '',
        accounts: '',
        debitOnly:'',
        isPayable:'',
        payableIr:'',
        invoiceTypeNotIn:'',
        paymentusPaymentDataStatusNotIn:'',
        invoiceNumber: '',
        ...(!this.props.isDrillMode ? {planInvoiceNumber:'', planNumber:''}:{})
    }, true)

    getActionColumn = () => {
        const {intl} = this.props;
        return {
            field: 'actions',
            label: intl.formatMessage({ id: 'invoice.invoice-table.actions' }),
            className: 'text-center datatable-action-col prevent-toggle no-export',
            sortable: false,
            responsivePriority: 2,
            display: this.createDatatableRowActions
        }
    }

    getPDFColumn = () => {
        const {invoiceMetadata, intl} = this.props
        return ({
            field: "PDF",
            label: intl.formatMessage({id:'ups.invoice.download.table.pdf'}),
            thClassName:"rightPadding",
            tdClassName:"noPadding",
            display: (data) => <div className="pointer"><PdfSvg width={28} height={28} onClick={()=>{
                this.downloadInvoiceAction(data,"pdf",invoiceMetadata?.invoiceDownloads?.pdf)
            }}/></div>,
            sortable:false,
            responsivePriority:4,
            noExport: true,
            hideable: false
        })
    }

    createDatatableCols = memoize(() => {
        const { savedColumnsType } = this.state;
        const { preferences, permissions} = this.props;
        const defaultColumns = getDefaultInvoiceColumns(this.props, this.toggleModal)
        const savedColumns = lodashGet(preferences, `${savedColumnsType}.savedColumns`);
        const customizableCols = (savedColumns && !R.isEmpty(savedColumns)) ? savedColumns.map(data=>{
            return defaultColumns[savedColumnCompatibility((typeof data === 'object') ? data.name : data)]
        }).filter((column)=>!!column) : Object.values(defaultColumns)
        return [
            ...customizableCols,
            ...permissions?.invoice_download ? [this.getPDFColumn()] : [],
            this.getActionColumn()
        ];
    });

    createDatatableRowActions = (data) => {
        const {intl, invoiceMetadata, permissions, businessUnit, businessUnitMessages, businessUnitKeys, sessionSelection, invoice: parentInvoice, adUserId, countryCode } = this.props;
        const {merchant} = sessionSelection;
        const creditInvoicesSupported = isCreditInvoiceSupported (sessionSelection);
        const timeZoneId = getMerchantTimeZoneId (sessionSelection);
        const components = [];        
        const actionLabel = intl.formatMessage({id:businessUnitKeys('moreActionsLabel')}, {invoiceNumber: data.invoiceNumber});
        
        if (permissions?.otp_payment && (isPayable (creditInvoicesSupported, timeZoneId, merchant) ({...data, parent: parentInvoice}))){
            components.push(
                <React.Fragment key={`dropdown-pay-invoice-${data.invoiceNumber}`}>
                    <MDBDropdownItem  id={`dropdown-pay-invoice-${data.invoiceNumber}`} onClick={() => this.payInvoice(data)}>
                        {businessUnitMessages('payInvoiceLabel')}
                    </MDBDropdownItem>
                    <MDBDropdownItem divider key={"dividier-1"}/>
                </React.Fragment>
            );
        }
        /* disabling xml download for impersonated session until enrollment api is changed to work with CCR access token or
           UPS access token generated via accessgateway. */
        if (permissions?.invoice_download) {
            const downloadEligibility = getDownloadEligibility(businessUnit, data.recordType, data.localCsvIr === "1");
            const xmlIsEnrolled = downloadEligibility.includes('xml') && (data.xmlIr === "1" || data.xmlIr === "undefined")
            const documentList = generateStatementList(data, intl, invoiceMetadata, false, businessUnit,  xmlIsEnrolled, downloadEligibility)
            documentList.forEach(({type,url})=>{
                components.push(
                    <MDBDropdownItem key={`dropdown-download-inv-${data.invoiceNumber}-${type}`} id={`dropdown-download-inv-${data.invoiceNumber}-${type}`}
                                     onClick={() => this.downloadInvoiceAction(data,type,url)} download
                    >
                        <FormattedMessage id={`btn.download-option-action-dropdown.${type?.toUpperCase()}`} values={{var:['US','CA'].includes(countryCode.toUpperCase()) ? '32' : '28'}}/>
                    </MDBDropdownItem>
                );
            })
        }
        if (permissions?.invoice_download && areInvoiceDocumentsDownloadable(merchant, data)) components.push(
            <MDBDropdownItem key={`dropdown-download-doc-${data.invoiceNumber}`} id={`dropdown-download-doc-${data.invoiceNumber}`}
                onClick={() => this.toggleModal('downloadAllDocument', {data,downloadType:'doc'})}
            >
                {intl.formatMessage({id: 'invoice.btn-download-documents.label'})}
            </MDBDropdownItem>
        );

        if (businessUnit === 'SCS' && isDisputesEnabled(merchant) && ((!adUserId && !data.inDispute) || data.inDispute)) {
            components.push(
                <MDBDropdownItem key={`dropdown-dispute-${data.invoiceNumber}`} id={`dropdown-dispute-${data.invoiceNumber}`} onClick={() => this.toggleModal('disputes', data)}>
                    {data.inDispute ?
                        <React.Fragment>
                            {intl.formatMessage({id: "invoice.disputes.in-dispute.label"})}
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={"pending-dispute-status-icon ml-2"}>
                                <path d="M256 32C114.6 32 0 125.1 0 240c0 49.6 21.4 95 57 130.7C44.5 421.1 2.7 466 2.2 466.5c-2.2 2.3-2.8 5.7-1.5 8.7S4.8 480 8 480c66.3 0 116-31.8 140.6-51.4 32.7 12.3 69 19.4 107.4 19.4 141.4 0 256-93.1 256-208S397.4 32 256 32zm0 336c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm25.4-110.4c-.8 8.2-7.7 14.4-15.9 14.4h-19c-8.2 0-15.1-6.2-15.9-14.4l-12.8-128c-.9-9.4 6.5-17.6 15.9-17.6h44.6c9.5 0 16.9 8.2 15.9 17.6l-12.8 128z"/>
                            </svg>
                        </React.Fragment> :
                        intl.formatMessage({id: "invoice.disputes.dispute.label"})
                    }
                </MDBDropdownItem>
            )
        }

        return (
            components.length > 0 && <MDBDropdown>
                <MDBDropdownToggle color="primary" className="custom-vertical-dropdown" id={`dropdown-toggle-${data.invoiceNumber}`} aria-label={actionLabel}>
                    <i className="fas fa-ellipsis-v"></i>
                </MDBDropdownToggle>
                <MDBDropdownMenu basic right>
                    {components}
                </MDBDropdownMenu>
            </MDBDropdown>
        )
    };

    payInvoice = (invoice, isAll) => {
        const {history, MAX_INVOICES, businessUnitConfigs} = this.props;
        const {selection} = this.state.tableState;
        let invoices = null
        if(!invoice) invoices = selection
        else if(invoice instanceof Map) invoices = invoice
        else invoices = new Map([[invoice.id, invoice]]);

        if (invoices.size <= 0) return;
        let total = 0
        let hasOverpayment = false
        invoices.forEach((invoice)=>{
            total += invoice.outstandingAmount
            hasOverpayment |= isOverpayment(invoice)
        })
        if(isAll && invoices.size === MAX_INVOICES){
            this.setState({notificationModalProps: {
                message:<FormattedMessage id={"invoice.max-invoices-reached"} values={{limit:MAX_INVOICES}}/>,
                title:"invoice.max-invoices-reached.title",
                callback: ()=>history.push('/ups/billing/otp', {invoices, isPlan: (businessUnitConfigs('hasPlans') && isPlanRoute (history))}),
                buttonTypes: "CONTINUE"
            }})
            return
        }
        if(hasOverpayment && total < 0) {
            this.setState({notificationModalProps: {message:"invoice.refund-not-eligible.body",title:"invoice.refund-not-eligible.title"}})
            return
        }
        history.push('/ups/billing/otp', {invoices, isPlan: (businessUnitConfigs('hasPlans') && isPlanRoute (history))});
    };

    updateStateInvoices = (invoices, doAutoSelect) => {
        const { businessUnitConfigs, MAX_INVOICES, sessionSelection, invoice: planInvoice, businessUnit } = this.props;
        const { filter } = this.state;
        const creditInvoicesSupported = isCreditInvoiceSupported (sessionSelection);
        const timeZoneId = getMerchantTimeZoneId (sessionSelection);
        const {merchant} = sessionSelection;

        this.setState(produce(draft=> {
            draft.loaded = true
            draft.invoiceList = invoices
            draft.payAllSet = new Map()
            // const planInvoicePayable = planInvoice ? checkPayableIr(planInvoice) : true;
            invoices?.forEach((data) => {
                if (draft.payAllSet.size < MAX_INVOICES) {
                    const payable = isPayable(creditInvoicesSupported, timeZoneId, merchant) ({...data, parent: planInvoice});
                    if (businessUnitConfigs('autoSelectPlanInvoices') &&
                        doAutoSelect &&
                        !data.isPayable && // plan payments
                        payable
                    // &&
                    // planInvoicePayable
                    ) {
                        draft.tableState.selection.set(data.id, data);
                    }
                    if (businessUnitConfigs('hasPayAllInvoicesTableButton') && payable) {
                        draft.payAllSet.set(data.id, data);
                    }
                }
            })
            // apply date filters for date range received in query params in case of payment reminder & past due.
            if (!lodashIsEmpty(filter) && filter.invoiceFilters) {
                const { invoiceFilters } = filter;
                const dateObj = { calendarOptionType: "CUSTOM", dateType: businessUnit === "SCS"? "1" : "2" };
                let initialFilter = {
                    ...invoiceFilters,
                    ...(businessUnit === "SCS" && { currencyCode: sessionSelection.merchant?.currencyCode }),
                    businessUnit,
                    ...(invoiceFilters.hasOwnProperty("dueDate") ? dateObj : {}),
                    filterType: "EMAIL"
                }
                draft.filter = initialFilter;
                draft.isFilterEnabled = this.isFilterEnabled(initialFilter)

                if (invoiceFilters.hasOwnProperty("dueDate")) {
                    const { dueDate } = invoiceFilters;
                    let selectedStartDate = moment(dueDate.dateBegin), selectedEndDate = (moment(dueDate.dateEnd));
                    draft.calendarDateType = dateObj.dateType;
                    draft.calendarOptionType = dateObj.calendarOptionType;
                    draft.calendarStartDate = { ...selectedStartDate };
                    draft.calendarEndDate = { ...selectedEndDate };
                }
            }
        }))
    }

    handleCalendarChange = (calendarOpts) => {
        const { calendarOptionType, dateType } = calendarOpts;
        if(dateType){
            this.setState({selectedDateType: dateType})
        } else {
            this.setState({
                selectedStartDate: null,
                selectedEndDate: null,
                selectedCalendarOptionType: calendarOptionType ?? 'AVAILABLE'
            });
        }
    }

    payAllInvoices = () => this.payInvoice(this.state.payAllSet, true)

    checkShowPayButton = memoize(() => {
        const {sessionSelection, invoice: planInvoice } = this.props;
        const {selection} = this.state.tableState;
        const {merchant} = sessionSelection
        // const hasPlans = businessUnitConfigs('hasPlans')
        if(selection?.size > 0){
            //if any are not payable, don't show the "pay" button.
            const creditInvoicesSupported = isCreditInvoiceSupported (sessionSelection);
            const timeZoneId = getMerchantTimeZoneId (sessionSelection);
            let payable = Array.from(selection.values())
                .every(invoice => isPayable (creditInvoicesSupported, timeZoneId, merchant) ({...invoice, parent: planInvoice }))
            
            if (payable) return true;
        }
        return false
    })

    selectInvoice = (data, defaultSelectAction) => {
        const {id} = data;
        const {MAX_INVOICES, invoice: parentInvoice} = this.props;
        const {selection} = this.state.tableState;
        const invoicePayable = checkPayableIr({...data, parent: parentInvoice})
        if (!!selection.get(id)) {
            defaultSelectAction()
        } else if (selection.size < MAX_INVOICES) {
            if (!invoicePayable && !this.state.notPayableOnlinePromptShown) {
                this.setState({
                    notPayableOnlinePromptShown:true,
                    notificationModalProps:{
                        title: 'invoice.not-payable-online',
                        message: 'invoice.not-payable-online-modal'
                    }
                })
            }
            defaultSelectAction()
        }
    }

    getCalendarButtonText(){
        return <><span><span id="datatable-date-selection" className="datatable-date-selection">{this.getDateRangeString()}</span> {calendarIcon}</span></>
    }

    renderDateRangePicker = ()=>{
        const {showDatePicker, handleCalendarSearch, handleCalendarChange} = this;
        const {isDrillMode, intl, businessUnit} = this.props;
        const {selectedStartDate, selectedEndDate, filter, showDatePickerModal, selectedCalendarOptionType} = this.state;
        return <CustomDateRangePicker
            startDate={selectedStartDate} // momentPropTypes.momentObj or null,
            endDate={selectedEndDate} // momentPropTypes.momentObj or null,
            handleDateChange={({startDate, endDate}) => this.setState({ selectedStartDate: startDate, selectedEndDate: endDate, selectedCalendarOptionType: 'CUSTOM' })}
            autoFocusEndDate={false}
            calendarInfoPosition="after"
            showDefaultInputIcon
            showDatePicker={showDatePicker}
            showDatePickerTest={showDatePickerModal}
            renderCalendarInfo={() => (
                <CalendarInfoPanel
                    handleSearch={(filter)=>{
                        handleCalendarSearch(filter)
                    }}
                    showDatePicker={showDatePicker}
                    filters={filter}
                    intl={intl}
                    isDrillMode={isDrillMode}
                    calendarOptionType={selectedCalendarOptionType}
                    handleCalendarChange={handleCalendarChange}
                    businessUnit={businessUnit}
                />
            )}
            dateRangeString={this.getDateRangeString()}
        />
    }

    rowCheckBoxProps = (rowData) => {
        const {intl, businessUnitKeys} = this.props;
        return {            
            ...(rowData ? {'aria-label': `${intl.formatMessage({id: businessUnitKeys('invoiceNumber')})} ${rowData.invoiceNumber}`} : '')
        };
    }

    downloadInvoiceAction = async (invoice, type, url) => {
        const {intl, businessUnit, userlocale, invoiceMetadata, adUserId, countryCode} = this.props;
        this.setState({spinning:true})
        if(type==="XML"){
            const eligible = await isInvoiceXmlDownloadEligible(invoice,countryCode,businessUnit,adUserId, false)
            if(!eligible) {
                this.setState({
                    notificationModalProps: {
                        message: "invoice.downloadInvoices.xml.notEligible.body",
                        title: "invoice.downloadInvoices.xml.notEligible.title",
                        alert:true
                    },
                    spinning:false
                })
                return
            }
        }
        handleInvoiceDownload(url, type, invoice, intl, false, businessUnit, userlocale)
            .catch((response)=>{
                const {errorMsg, errorTitle} = getInvoiceDownloadErrorMsg(response, invoiceMetadata)
                this.setState({notificationModalProps: {
                    message: errorMsg,
                    title: errorTitle,
                    alert:true
                }})
            })
            .finally(()=>{
                this.setState({spinning:false})
            })
    }

    render() {
        const { toggleModal, showDatePicker, doInvoiceSearch } = this;
        const { intl, isDrillMode, MAX_INVOICES, defaultButtonProps, businessUnitKeys, businessUnitConfigs, preferences, permissions,caption } = this.props;
        const { loaded, invoiceList, isFilterEnabled, tableState, data, filter, savedColumnsType, error, payAllSet, savedSearchCriteriaType } = this.state;
        const {selection, filteredData} = tableState;

        // modals
        const { exportDataModal, downloadMultipleInvoiceModal, downloadAllDocumentModal, filterModal, columnSettingsModal, viewAccountPaymentHistoryModal, disputesModal, showInvoiceErrorModal, notificationModalProps} = this.state;
        const savedColumns = lodashGet(preferences, `${savedColumnsType}.savedColumns`)

        //these memoized functions will only rerun on a render when the input arguments change
        const columns = this.createDatatableCols(intl.locale, savedColumns)
        const showPayButton = this.checkShowPayButton(selection)
        
        if (!loaded) return null;
        if (error instanceof TypeError) {
            return <Exception error={error} />
        } else {
            return <>
                {this.renderDateRangePicker()}
                {selection?.size === MAX_INVOICES || (isDrillMode && invoiceList.length > MAX_INVOICES) &&
                    <div className="alert alert-past-due" role="alert">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" className={"svg-icon"} viewBox="0 0 32 32">
                            <title><FormattedMessage id="alert.label" /></title>
                            <path d="M32.313 26.563l-14.438-23.125c-0.375-0.5-0.938-0.75-1.563-0.75-0.688 0-1.188 0.25-1.625 0.75l-14.375 23.125c-0.438 0.5-0.438 1.313 0 1.813 0.25 0.563 0.938 0.938 1.563 0.938h28.813c0.688 0 1.375-0.375 1.625-0.938 0.25-0.5 0.25-1.313 0-1.813zM16.313 27.438h-14.125l5.688-9.188c0.188-0.125 0.188-0.125 0.313-0.25l8.125-13.063 8.125 13.063c0 0.125 0.125 0.125 0.25 0.25l5.75 9.188h-14.125zM16.688 24.563h-0.813c-0.375 0-0.625-0.313-0.625-0.688v-0.813c0-0.375 0.25-0.688 0.625-0.688h0.813c0.438 0 0.688 0.313 0.688 0.688v0.813c0 0.25-0.25 0.688-0.688 0.688M17.125 20.688h-1.5c-0.25 0-0.375-0.125-0.375-0.438l-0.563-7.563c0-0.563 0.438-0.938 0.813-0.938h1.625c0.375 0 0.75 0.375 0.75 0.938l-0.5 7.563c0 0.313-0.125 0.438-0.25 0.438"></path>
                        </svg>
                        <span className="alert-msg">
                            {(isDrillMode && invoiceList.length > MAX_INVOICES) && <FormattedMessage id={'invoice.max-selected-plan-invoices'}/>}
                            <FormattedMessage id={'invoice.max-selected-invoices'} values={{limit:MAX_INVOICES}}/>
                        </span>
                    </div>
                }
                <FilteredDataTableV3
                    name={'invoice-table'}
                    caption={caption}
                    className={'table-bordered-rows'}
                    trClassName={({invoiceStatus})=>(invoiceStatus === invoiceStatuses.PAST_DUE)?'past-due-border-left':''}
                    data={invoiceList}
                    columns={columns}                    
                    rowCheckBoxProps={this.rowCheckBoxProps}
                    buttons={[
                        {
                            className:'btn-pay-all-invoice',
                            text:<FormattedMessage id={'invoice.datatable.btn-pay-all.title'}/>,
                            hide: !businessUnitConfigs('hasPayAllInvoicesTableButton') || (payAllSet.size === 0) || (selection.size > 0) || !permissions?.otp_payment,
                            action: this.payAllInvoices,
                        },
                        {
                            className:'btn-pay-invoice',
                            text:<FormattedMessage id={'invoice.datatable.btn-pay.title'}/>,
                            hide: !showPayButton || !permissions?.otp_payment,
                            action: ()=>this.payInvoice(selection),
                        },
                        {
                            className:'btn-download-invoices btn-icon-mobile',
                            type: "secondary",
                            text: <><i className="fas fa-download d-inline-block d-xl-none" aria-label={intl.formatMessage({id: selection.size > 1? businessUnitKeys('downloadInvoices'):businessUnitKeys('downloadInvoice')})} /><span className="d-none d-xl-inline"><FormattedMessage id={selection.size > 1? businessUnitKeys('downloadInvoices'):businessUnitKeys('downloadInvoice')}/></span></>,
                            action:()=>toggleModal('downloadMultipleInvoice'),
                            hide: ((selection.size === 0) || !permissions?.invoice_download),
                            otherProps: {disabled: (selection.size > 10)}
                        },
                        {type: "filter", action:()=>toggleModal('filter')},
                        {...defaultButtonProps.columnSettings, action:()=>toggleModal('columnSettings')},
                        {...defaultButtonProps.calendar, text: this.getCalendarButtonText(), action:showDatePicker},
                        {type: "print"},
                        {...defaultButtonProps.export, action:()=>toggleModal('exportData')}
                    ]}
                    defaultSorting={({invoiceStatus, invoiceDate})=>(
                        `${invoiceStatus === invoiceStatuses.PAST_DUE?'1':'0'}${invoiceStatus === invoiceStatuses.CREDIT?'1':'0'}${invoiceStatus === invoiceStatuses.OPEN?'1':'0'}${invoiceDate}`
                    )}
                    defaultSortDirection={SORTED_DSC}
                    externalFilterActive={isFilterEnabled}
                    onClearFilter={this.clearFilter}
                    tableState={tableState}
                    tableStateAction={(action)=>this.setState(action)}
                    searchable
                    delayedSearch={invoiceList.length > 1000}
                    printable
                    responsive
                    selectEnabled
                    onSelect={this.selectInvoice}
                    rowHeaderField ={'invoiceNumber'}
                    filterType={filter.filterType}
                />
                <ExportDataModal
                    isOpen={exportDataModal}
                    toggleModal={toggleModal}
                    handleExport={(type)=>exportData(filteredData,columns,tableState,document.title,type)}
                    an_label={'export-account-invoices-data'}
                />
                {downloadMultipleInvoiceModal &&
                    <DownloadMultipleInvoiceModal
                        isOpen={downloadMultipleInvoiceModal}
                        data={selection}
                        toggleModal={toggleModal}
                    />
                }
                {downloadAllDocumentModal &&
                    <DownloadAllDocumentModal
                        isOpen={downloadAllDocumentModal}
                        invoice={data?.data}
                        downloadType={data?.downloadType}
                        toggleModal={toggleModal}
                        showToggleDisplayMode
                        isPlanInvoice={false}
                    />
                }
                {filterModal &&
                    <InvoiceSearchCriteriaModal
                        isOpen={filterModal}
                        invoice={this.props.invoice}
                        toggleModal={toggleModal}
                        isDrillMode={isDrillMode}
                        runFilter={this.runFilter}
                        clearFilter={this.clearFilter}
                        invoiceFilters={filter}
                        savedPreferenceType={savedSearchCriteriaType}
                    />
                }
                <ColumnSettingsModal
                    modalName={'columnSettings'}
                    isOpen={columnSettingsModal}
                    columns={() => getDefaultInvoiceColumns(this.props, toggleModal)}
                    columnCompatibilityFunc={savedColumnCompatibility}
                    toggleModal={toggleModal}
                    savedPreferenceType={savedColumnsType}
                />
                {viewAccountPaymentHistoryModal &&
                    <ViewAccountPaymentHistoryModal
                        key={'view-account-payment-modal'}
                        isOpen={viewAccountPaymentHistoryModal}
                        data={data}
                        toggleModal={toggleModal}
                    />
                }
                {disputesModal &&
                    <DisputesModal
                        key={`disputes-modal`}
                        isOpen={disputesModal}
                        invoice={data}
                        toggleModal={toggleModal}
                        disputeLevel={'invoice'}
                        updateData={doInvoiceSearch}
                    />
                }
                <InvoiceErrorModal isOpen={showInvoiceErrorModal} toggleModal={toggleModal}/>
                {!R.isEmpty(notificationModalProps) && <NotificationModal
                    isOpen={!R.isEmpty(notificationModalProps)}
                    closeModal={() => this.setState({ notificationModalProps: {} })}
                    {...notificationModalProps}
                />}
                <Spinner isSpinning={this.state.spinning}/>
            </>
        }
    }
}

function mapStateToProps(state, ownProps) {
    // many of these props are passed into invoice-util functions
    const { intl } = ownProps;
    return {
        preferences: state.identity?.preferences,
        invoiceMetadata: state.invoice.invoiceMetadata,
        permissions : state.auth.user.permissions,
        businessUnit: state.auth.user.selectedPaymentType,
        businessUnitConfigs:  getConfigByBU(state.auth.user.selectedPaymentType),
        businessUnitKeys: getKeyByBU('invoiceTable')(state.auth.user.selectedPaymentType),
        businessUnitMessages: getMessageByBU('invoiceTable')(intl)(state.auth.user.selectedPaymentType),
        countryCode: state.auth.user.selectedCountry,
        MAX_INVOICES: getMaxInvoices(state.config?.sessionSelection?.merchant),
        sessionSelection: state.config?.sessionSelection,
        defaultButtonProps: defaultButtonProps(intl),
        adUserId: state.auth.user?.adUserId,
        userlocale: state.auth.user?.locale,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        identityActions: bindActionCreators(identityActions, dispatch),
        invoiceActions: bindActionCreators(invoiceActions, dispatch),
        paymentActivityActions: bindActionCreators(paymentActivityActions, dispatch)
    }
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceTable)));


