import React, {useState, useEffect, useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {MDBRow, MDBCol} from "mdbreact";

import Details from ".";
import InputFieldIntl from "../../../components/InputFieldIntl";
import DisplayOnlyField from "../../../components/DisplayOnlyField";

import {InputRestrictors} from '../../../utils/validation/standard-validations';
import {maskIban, maskString} from '../../../utils/utils';
import {pmCatRequiresAddress} from '../../../utils/payment-utils';
import SelectFieldIntl from "../../SelectFieldIntl";
import AchAgreementConfirmation from "../AchAgreementConfirmation";

const trim = (value) => value?.replace(/\s/g,'').toLowerCase()

const debtorIdTypeOptions = [
    {msgId: "manage-user.dropdown.selection-title", value: ""},
    {msgId: "payment-method.scb.debtor-id-type.hkid", value: "hkid"},
    {msgId: "payment-method.scb.debtor-id-type.passport", value: "passport"},
    {msgId: "payment-method.scb.debtor-id-type.BR", value: "BR"},
    {msgId: "payment-method.scb.debtor-id-type.CI", value: "CI"},
    {msgId: "payment-method.scb.debtor-id-type.others", value: "others"}
]

export default function SCBDetails({
                                        isModal, country, pmCategory, paymentMethod, submitAction, handleCancel,
                                        hideNickField, controlled, isACHAuthRequired = false
                                    }) {
    const captureAddress = pmCatRequiresAddress(pmCategory);
    const [localPaymentMethod, setLocalPaymentMethod] = useState({});

    useEffect(() => {
        const pm = paymentMethod;
        if (pm) {
            setLocalPaymentMethod({
                cardNickName: pm.cardNickName,
                accountNumber: pm.accountNumber,
                bic: pm.bic,
                bankId: pm.bankId,
                debtorIdType: pm.debtorIdType,
                debtorId: pm.debtorId,
                cardHolderName: pm.cardHolderName,
                line1: pm.address?.line1,
                city: pm.address?.city,
                zipCode: pm.address?.zipCode
            });
        }
    }, []);

    useEffect(() => {
        if (controlled) {
            validatePM();
        }
    }, [localPaymentMethod]);

    const handleInput = e => {
        const {name, value, checked, type} = e.target;
        switch (type) {
            case 'text':
                setLocalPaymentMethod({...localPaymentMethod, [name]: value});
                break;
            case 'checkbox':
                setLocalPaymentMethod({...localPaymentMethod, [name]: checked});
                break;
            case 'select-one':
                setLocalPaymentMethod({...localPaymentMethod, [name]: value});
                break;
            default: break;
        }
    };

    const validatePM = () => {
        const {
            accountNumber, bic, bankId, debtorIdType, debtorId, cardHolderName, cardNickName,
            line1, city, zipCode, authorizeDD
        } = localPaymentMethod;

        const data = {
            methodType: pmCategory,
            accountNumber,
            bic,
            bankId,
            debtorIdType,
            debtorId,
            cardHolderName,
            cardNickName,
            authorizeDD,
            categoryCode: pmCategory
        };
        if (captureAddress) data.address = {country, line1, city, zipCode};
        if (paymentMethod) data.id = paymentMethod.id;

        return submitAction?.(data);
    }

    const {accountNumber, bic, bankId, debtorIdType, debtorId, cardHolderName, authorizeDD, cardNickName, line1, city, zipCode} = localPaymentMethod;

    const debtorIdTypeLabel = useMemo(
        ()=>debtorIdType && debtorIdTypeOptions.find(option=>(option.value === debtorIdType))?.msgId
        , [debtorIdType]
    )

    const form = <React.Fragment>
        <MDBRow>

            {controlled || !paymentMethod
                ? <MDBCol size="12" md="6">
                    <InputFieldIntl name="accountNumber" id="accountNumber"
                                    label={<FormattedMessage id="payment-method.echeck.bank-account-number"/>}
                                    value={accountNumber}
                                    onChange={handleInput}
                                    hint="payment-method.echeck.bank-account-number.placeholder"
                                    maxLength={35}
                                    restrict={InputRestrictors.alphanumeric}
                                    required
                                    validations={[
                                        ["required", "field.required"],
                                        ['alphanumeric', "field.alphanumeric"],
                                        ["maxlength", "field.invalid", {max: 35}]
                                    ]}
                    />
                </MDBCol>
                : <MDBCol size="12" md="6">
                    <DisplayOnlyField
                        label={<FormattedMessage id="payment-method.echeck.bank-account-number-readonly"/>}
                        value={accountNumber}
                        wrapperMode
                    />
                </MDBCol>
            }

            {controlled || !paymentMethod
                ? <MDBCol size="12" md="2">
                    <InputFieldIntl name="bic" id="bic"
                                    label={<FormattedMessage id="payment-method.sepa.bic-number"/>}
                                    value={bic}
                                    onChange={handleInput}
                                    hint="payment-method.sepa.bic-number.placeholder"
                                    maxLength={11}
                                    required
                                    restrict={InputRestrictors.alphanumeric}
                                    validations={[
                                        ['required', "field.required"],
                                        ['alphanumeric', "field.alphanumeric"]
                                        ['isBIC', 'field.invalidBIC'],
                                        ['notCreditCard', "field.notCreditCard"]
                                    ]}
                    />
                </MDBCol>
                : <MDBCol size="12" md="2">
                    <DisplayOnlyField
                        label={<FormattedMessage id="payment-method.sepa.bic-number"/>}
                        value={maskString(bic)}
                        wrapperMode
                    />
                </MDBCol>
            }

            {controlled || !paymentMethod
                ? <MDBCol size="12" md="4">
                    <InputFieldIntl name="bankId" id="bankId"
                                    label={<FormattedMessage id="payment-method.scb.bankId"/>}
                                    value={bankId}
                                    onChange={handleInput}
                                    hint="payment-method.scb.bankId.placeholder"
                                    maxLength={3}
                                    required
                                    restrict={InputRestrictors.numeric}
                                    validations={[
                                        ['required', "field.required"],
                                        ['numeric', "field.numeric"],
                                        ["minmaxlength", "field.invalid", {min: 3, max: 3}]
                                    ]}
                    />
                </MDBCol>
                : <MDBCol size="12" md="4">
                    <DisplayOnlyField
                        label={<FormattedMessage id="payment-method.scb.bankId"/>}
                        value={bankId}
                        wrapperMode
                    />
                </MDBCol>
            }

            {controlled || !paymentMethod
                ? <MDBCol size="12" md="6">
                    <InputFieldIntl name="cardHolderName" id="cardHolderName"
                                    label={<FormattedMessage id="payment-method.echeck.account-holder-name"/>}
                                    value={cardHolderName}
                                    onChange={handleInput}
                                    hint="payment-method.echeck.account-holder-name.placeholder"
                                    maxLength={256}
                                    required
                                    validations={[
                                        ['required', "field.required"],
                                        ['alphaNumericAndPunctuation', "field.alphaNumericAndPunctuation"],
                                        ['notCreditCard', "field.notCreditCard"]
                                    ]}
                    />
                </MDBCol>
                : <MDBCol size="12" md="6">
                    <DisplayOnlyField
                        label={<FormattedMessage id="payment-method.echeck.account-holder-name"/>}
                        value={cardHolderName}
                        wrapperMode
                    />
                </MDBCol>
            }

            <MDBCol size="12" md="6"/>

            {controlled || !paymentMethod
                ? <MDBCol size="12" md="6">
                    <SelectFieldIntl name="debtorIdType" id="debtorIdType"
                                     value={debtorIdType}
                                     selectOptions={debtorIdTypeOptions}
                                     label={<FormattedMessage id="payment-method.scb.debtor-id-types"/>}
                                     setLabelBefore required
                                     onChange={handleInput}
                                     validations={[
                                         ['required', "field.required"]
                                     ]}
                    />
                </MDBCol>
                : <MDBCol size="12" md="6">
                    <DisplayOnlyField
                        label={<FormattedMessage id="payment-method.scb.debtor-id-types"/>}
                        value={debtorIdTypeLabel && <FormattedMessage id={debtorIdTypeLabel}/>}
                        wrapperMode
                    />
                </MDBCol>
            }

            {controlled || !paymentMethod
                ? <MDBCol size="12" md="6">
                    <InputFieldIntl name="debtorId" id="debtorId"
                                    label={<FormattedMessage id="payment-method.scb.debtor-id"/>}
                                    value={debtorId}
                                    onChange={handleInput}
                                    hint="payment-method.scb.debtor-id.placeholder"
                                    maxLength={50}
                                    required
                                    validations={[
                                        ['required', "field.required"],
                                        ['alphaNumericAndPunctuation', "field.alphaNumericAndPunctuation"]
                                    ]}
                    />
                </MDBCol>
                : <MDBCol size="12" md="6">
                    <DisplayOnlyField
                        label={<FormattedMessage id="payment-method.scb.debtor-id"/>}
                        value={debtorId}
                        wrapperMode
                    />
                </MDBCol>
            }

            {!hideNickField &&
                <MDBCol size="12" md="6">
                    <InputFieldIntl name="cardNickName" id="cardNickName"
                                    label={<FormattedMessage id="payment-method.card-nickname"/>}
                                    value={cardNickName}
                                    onChange={handleInput}
                                    onBlur={(e)=>{
                                        e.target.value = e.target.value.trim()
                                        handleInput(e)
                                    }}
                                    hint="payment-method.card-nickname"
                                    maxLength={32}
                                    validations={[
                                        (value)=>((trim(value)?.includes(trim(accountNumber)) || trim(value)?.includes(trim(bankId))) ? "field.notCreditCard" : "")
                                    ]}
                    />
                </MDBCol>
            }

            {captureAddress &&
                <MDBCol size="12" md="6">
                    <InputFieldIntl name="line1" id="line1"
                                    label={<FormattedMessage id="payment-method.billing-address.line1"/>}
                                    value={line1}
                                    onChange={handleInput}
                                    hint="payment-method.billing-address.line1.placeholder"
                                    maxLength={32}
                                    required
                                    validations={[
                                        ['required', "field.required"],
                                        ['notCreditCard', "field.notCreditCard"],
                                        ['alphaNumericAndPunctuation', "field.alphaNumericAndPunctuation"]
                                    ]}
                    />
                </MDBCol>
            }

            {captureAddress &&
                <MDBCol size="12" md="6">
                    <InputFieldIntl name="city" id="city"
                                    label={<FormattedMessage id="payment-method.billing-address.city"/>}
                                    value={city}
                                    onChange={handleInput}
                                    hint="payment-method.billing-address.city.placeholder"
                                    maxLength={32}
                                    required
                                    validations={[
                                        ['required', "field.required"],
                                        ['notCreditCard', "field.notCreditCard"],
                                        ['alphaAndPunctuation', "field.alphaAndPunctuation"]
                                    ]}
                    />
                </MDBCol>
            }

            {captureAddress &&
                <MDBCol size="6" md="3">
                    <InputFieldIntl name="zipCode" id="zipCode"
                                    label={<FormattedMessage id="payment-method.billing-address.post-code"/>}
                                    value={zipCode}
                                    onChange={handleInput}
                                    hint="payment-method.billing-address.post-code.placeholder"
                                    maxLength={30}
                                    required
                                    validations={[
                                        ['required', "field.required"],
                                        ['zipCode', "field.zipCode", {selectedCountry: country}]
                                    ]}
                    />
                </MDBCol>
            }


        </MDBRow>
        <MDBRow>
            {isACHAuthRequired &&
                <AchAgreementConfirmation
                    authorizeDD={authorizeDD}
                    onAuthorizeACH={handleInput}
                    type={pmCategory}
                />
            }
        </MDBRow>
    </React.Fragment>;

    if (controlled) return form;

    return <Details
        form={form}
        isModal={isModal}
        onSubmit={validatePM}
        onCancel={handleCancel}
        extraFooterCssClass="mt-3"
    />;
}
