import React, {useState, useEffect} from "react";
import {FormattedMessage} from "react-intl";
import {MDBRow, MDBCol} from "mdbreact";

import Details from "..";
import InputFieldIntl from "../../../../components/InputFieldIntl";
import DisplayOnlyField from "../../../../components/DisplayOnlyField";

import {InputRestrictors} from '../../../../utils/validation/standard-validations';
import {maskIban, maskString} from '../../../../utils/utils';
import {pmCatRequiresAddress} from '../../../../utils/payment-utils';

const trim = (value) => value?.replace(/\s/g,'').toLowerCase()

export default function SepaDetails({
    isModal, country, pmCategory, paymentMethod, submitAction, handleCancel,
    hideNickField, controlled
}) {
    const captureAddress = pmCatRequiresAddress(pmCategory);
    const [localPaymentMethod, setLocalPaymentMethod] = useState({});

    useEffect(() => {
        const pm = paymentMethod;
        if (pm) {
            setLocalPaymentMethod({
                cardNickName: pm.cardNickName,
                cardNumber: pm.accountNumber,
                bic: pm.bic,
                cardHolderName: pm.cardHolderName,
                line1: pm.address?.line1,
                city: pm.address?.city,
                zipCode: pm.address?.zipCode
            });
        }
    }, []);

    useEffect(() => {
        if (controlled) {
            validatePM();
        }
    }, [localPaymentMethod]);

    const handleInput = e => {
        const {name, value, checked, type} = e.target;
        switch (type) {
            case 'text': 
                setLocalPaymentMethod({...localPaymentMethod, [name]: value});
                break;
            case 'checkbox': 
                setLocalPaymentMethod({...localPaymentMethod, [name]: checked});
                break;
            default: break;
        }
    };

    const validatePM = () => {
        const {
            cardNumber, bic, cardHolderName, cardNickName,
            line1, city, zipCode
        } = localPaymentMethod;

        const data = {
            methodType: pmCategory,
            accountNumber: cardNumber,
            bic,
            cardHolderName,
            cardNickName,
            categoryCode: pmCategory
        };
        if (captureAddress) data.address = {country, line1, city, zipCode};
        if (paymentMethod) data.id = paymentMethod.id;

        return submitAction?.(data);
    }

    const {cardNumber, bic, cardHolderName, cardNickName, line1, city, zipCode} = localPaymentMethod;

    const form = <React.Fragment>
        <MDBRow>
            {controlled || !paymentMethod
                ? <MDBCol size="12" md="6">
                    <InputFieldIntl name="cardNumber" id="cardNumber"
                        label={<FormattedMessage id="payment-method.sepa.iban-number"/>}
                        value={cardNumber}
                        onChange={handleInput}
                        hint="payment-method.sepa.iban-number.placeholder"
                        maxLength={34}
                        required
                        restrict={InputRestrictors.alphanumeric}
                        validations={[
                            ['required', "field.required"],
                            // ['alphanumeric', "field.alphanumeric"],
                            ['isIBAN', 'field.invalidIBAN']
                        ]}
                    />
                </MDBCol>
                : <MDBCol size="12" md="6">
                    <DisplayOnlyField
                        label={<FormattedMessage id="payment-method.sepa.iban-number"/>}
                        value={maskIban(cardNumber)}
                        wrapperMode
                    />
                </MDBCol>
            }

            {controlled || !paymentMethod
                ? <MDBCol size="12" md="6">
                    <InputFieldIntl name="bic" id="bic"
                        label={<FormattedMessage id="payment-method.sepa.bic-number"/>}
                        value={bic}
                        onChange={handleInput}
                        hint="payment-method.sepa.bic-number.placeholder"
                        maxLength={11}
                        required
                        restrict={InputRestrictors.alphanumeric}
                        validations={[
                            ['required', "field.required"],
                            // ['alphanumeric', "field.alphanumeric"]
                            ['isBIC', 'field.invalidBIC'],
                            ['notCreditCard', "field.notCreditCard"]
                        ]}
                    />
                </MDBCol>
                : <MDBCol size="12" md="6">
                    <DisplayOnlyField
                        label={<FormattedMessage id="payment-method.sepa.bic-number"/>}
                        value={maskString(bic)}
                        wrapperMode
                    />
                </MDBCol>
            }

            <MDBCol size="12" md="6">
                <InputFieldIntl name="cardHolderName" id="cardHolderName"
                    label={<FormattedMessage id="payment-method.echeck.account-holder-name"/>}
                    value={cardHolderName}
                    onChange={handleInput}
                    hint="payment-method.echeck.account-holder-name.placeholder"
                    maxLength={256}
                    required
                    validations={[
                        ['required', "field.required"],
                        ['alphaNumericAndPunctuation', "field.alphaNumericAndPunctuation"],
                        ['notCreditCard', "field.notCreditCard"]
                    ]}
                />
            </MDBCol>

            {!hideNickField &&
                <MDBCol size="12" md="6">
                    <InputFieldIntl name="cardNickName" id="cardNickName"
                        label={<FormattedMessage id="payment-method.card-nickname"/>}
                        value={cardNickName}
                        onChange={handleInput}
                        onBlur={(e)=>{
                            e.target.value = e.target.value.trim()
                            handleInput(e)
                        }}
                        hint="payment-method.card-nickname"
                        maxLength={32}
                        validations={[
                            (value)=>((trim(value)?.includes(trim(cardNumber)) || trim(value)?.includes(trim(bic))) ? "field.notCreditCard" : "")
                        ]}
                    />
                </MDBCol>
            }

            {captureAddress &&
                <MDBCol size="12" md="6">
                    <InputFieldIntl name="line1" id="line1"
                        label={<FormattedMessage id="payment-method.billing-address.line1"/>}
                        value={line1}
                        onChange={handleInput}
                        hint="payment-method.billing-address.line1.placeholder"
                        maxLength={32}
                        required
                        validations={[
                            ['required', "field.required"],
                            ['notCreditCard', "field.notCreditCard"],
                            ['alphaNumericAndPunctuation', "field.alphaNumericAndPunctuation"]
                        ]}
                    />
                </MDBCol>
            }

            {captureAddress &&
                <MDBCol size="12" md="6">
                    <InputFieldIntl name="city" id="city"
                        label={<FormattedMessage id="payment-method.billing-address.city"/>}
                        value={city}
                        onChange={handleInput}
                        hint="payment-method.billing-address.city.placeholder"
                        maxLength={32}
                        required
                        validations={[
                            ['required', "field.required"],
                            ['notCreditCard', "field.notCreditCard"],
                            ['alphaAndPunctuation', "field.alphaAndPunctuation"]
                        ]}
                    />
                </MDBCol>
            }

            {captureAddress &&
                <MDBCol size="6" md="3">
                    <InputFieldIntl name="zipCode" id="zipCode"
                        label={<FormattedMessage id="payment-method.billing-address.post-code"/>}
                        value={zipCode}
                        onChange={handleInput}
                        hint="payment-method.billing-address.post-code.placeholder"
                        maxLength={10}
                        required
                        validations={[
                            ['required', "field.required"],
                            ['zipCode', "field.zipCode", {selectedCountry: country}]
                        ]}
                    />
                </MDBCol>
            }
        </MDBRow>
    </React.Fragment>;

    if (controlled) return form;

    return <Details
        form={form}
        isModal={isModal}
        onSubmit={validatePM}
        onCancel={handleCancel}
        extraFooterCssClass="mt-3"
    />;
}
