import React, { Component } from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import classNames from "classnames";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import ValidationErrors from "../ValidationErrors";
import attachValidator from "../../utils/validation/attach-validator.js"
import * as validationActions from "../../actions/validation-action";
import * as errorActions from "../../actions/error-action";
import NumberFormat from "react-number-format";

// Modified MDBInput to support placeholder with react-intl
// Because <FormattedMessage /> is a component which cannot be placed in placeholder which expects a raw String.
class MaskedNumberField extends Component{
    constructor(props){
        super(props)
        this.onChange = this.onChange.bind(this)
        this.onBlur = this.onBlur.bind(this)
        if(this.props.validations) attachValidator.call(this)
    }
    componentDidMount(){
        let {name, validations} = this.props;
        if(validations) this.validator.register(name)
    }
    componentWillUnmount(){
        let {name, validations, errorActions, id} = this.props;
        if(validations) this.validator.deregister(name)
        //clear backend error
        errorActions.clearFieldError({id})
    }
    onChange(e){
        let {onChange, validations, name, restrict, id, errorActions, unformat} = this.props;
        let val = e.target.value
        if(restrict){
            //restrict enterable characters to those that return true from the restrict() function
            if(restrict(val)) {
                this.oldValue = val;
            } else {
                e.target.value = this.oldValue;
                e.preventDefault();
                e.stopPropagation();
                return;
            }
        }

        if(unformat) {
            val = unformat(val);
            e.target.value = val;
        }

        if(validations){
            clearTimeout(this.validationTimer)
            this.validationTimer = setTimeout(() => {
                this.validator.validate(name, val)
            }, 500)
        }
        //clear backend error
        errorActions.clearFieldError({id})
        if(onChange) onChange(e)
    }
    onBlur(e){
        let {onBlur, validations, name, id, errorActions, unformat} = this.props;
        let val = e.target.value
        if(unformat) {
            val = unformat(val);
            e.target.value = val;
        }
        if(validations){
            clearTimeout(this.validationTimer)
            this.validator.validate(name, val)
        }
        //clear backend error
        errorActions.clearFieldError({id})
        if(onBlur) onBlur(e)
    }
    render(){
        const {
            name, id, type, label, labelRightElement, hint, value, onChange, onBlur,
            iconClass, icon, onIconClick, tabIndex, containerClass, className, intl, labelClass,
            errors, vFields, ownVState, vState, validations, validationActions, errorActions, restrict, required,
            noContainer, format, unformat, extraLabelInfo, ...rest
        } = this.props;
        const error = errors && errors.find(e => e.split('.')[0] === name );
        let messages = [];
        //backend error
        if(error) messages.push(error)
        //frontend validation errors
        if(ownVState && ownVState.messages) messages = messages.concat(ownVState.messages)
        // if type is radio or checkbox, please ensure they each have a unique ID
        let field = (
            <React.Fragment>
                <NumberFormat
                    format={format}
                    name={name}
                    className={classNames(className, "masked-number-input form-control custom-input number-format-input")}
                    id={name}
                    type='text'
                    placeholder={hint ? intl.formatMessage({ id: hint }) : ''}
                    value={value}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    tabIndex={tabIndex}
                    aria-required={!!required}
                    {...rest}
                />
                <label htmlFor={name} className={classNames("custom-input-label",labelClass)}>{
                    <span>
                        <span>{label}</span>
                        <span>{required && (typeof label !== 'undefined') && "*"}</span>
                        &nbsp; {extraLabelInfo}
                    </span>
                }</label>
                <ValidationErrors name={name} messages={messages} values={{label}} intl={intl}/>
            </React.Fragment>
        )
        return (
            <React.Fragment>
                {noContainer
                    ? field
                    : <div className={"md-form custom-input-form"}>{field}</div>
                }
            </React.Fragment>
        )
    }
}
function mapStateToProps(state, ownprops) {
    return {
        errors: state.error.errors,
        ...(ownprops.validations ? {
            //required for attaching validator
            vFields: state.validation.vFields,
            vState: state.validation.vState,
            //required for checking field validation state
            ownVState:state.validation.vState[ownprops.name]
        }: {})
    }
}

function mapDispatchToProps(dispatch, ownprops) {
    return {
        //required for clearing backend errors
        errorActions: bindActionCreators(errorActions, dispatch),
        ...(ownprops.validations ? {
            //required for attaching validator
            validationActions: bindActionCreators(validationActions, dispatch)
        } : {})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MaskedNumberField));
