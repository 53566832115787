import React, {useEffect, useMemo, useReducer, useState} from 'react';
import {MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol} from 'mdbreact';
import {FormattedMessage, useIntl} from 'react-intl';

import ReactPortalModal from "../../../ReactPortalModal";
import {saveIdentityPreferences} from "../../../../actions/identity-action";
import {useDispatch} from "react-redux";
import CheckboxIntl from "../../../CheckboxIntl";
import produce from "immer";
import {isEmpty} from "ramda";
import {clearSessionFilter, setSessionFilter} from "../../../../utils/utils";
const _ = require('lodash');

const INITIAL_STATE = {
    curFilter: {},
    defaultFilter: {},
    saveFilter: false
}

function reducer(state, {type, filterName, filter, saveFilter}){
    return produce(newState=>{
        switch (type) {
            case 'setCurFilterVal':
                newState.curFilter[filterName] = filter; break;
            case 'setCurFilter':
                newState.curFilter = filter; break;
            case 'setDefaultFilter':
                newState.defaultFilter = filter; break;
            case "setSaveFilter":
                newState.saveFilter = saveFilter; break;
        }
    })(state)
}

function getActions(setState){
    return {
        setCurFilter: (filter)=>setState({type: 'setCurFilter', filter}),
        setCurFilterVal: (filterName, filter)=>setState({type: 'setCurFilterVal', filterName, filter}),
        setDefaultFilter: (filter)=>setState({type: 'setDefaultFilter', filter}),
        setSaveFilter: (saveFilter)=>setState({type: 'setSaveFilter', saveFilter})
    }
}

export default function FilterModal({filterName, isOpen, toggleModal, backdrop, setFilter, filter, saveParseFuncs, trimMultiSelectAll = true, renderFilterCriteria, businessUnit, country}) {
    const [state, setState] = useReducer(reducer, INITIAL_STATE)
    const {setCurFilter, setCurFilterVal, setDefaultFilter, setSaveFilter} = getActions(setState)
    const {curFilter, defaultFilter, saveFilter} = state
    const dispatch = useDispatch()
    const intl = useIntl();
    const modalHeading = "search.custom-filter.filter.label";
    useEffect(()=>{
        setCurFilter(filter)
    }, [filter])
    function closeModal(){
        setSaveFilter(false)
        toggleModal()
    }
    function reset(){
        closeModal()
        if(filter) setCurFilter(filter)
        else setCurFilter(defaultFilter)
    }
    if (isOpen) {
        return (<ReactPortalModal isOpen={isOpen} an_label={filterName ? _.kebabCase(filterName) : "filter"}>
            <MDBModal isOpen={isOpen} toggle={reset} size="md" backdrop={backdrop} centered disableBackdrop disableFocusTrap={false} labelledBy={intl.formatMessage({ id: modalHeading })}>
                <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={reset}>
                    <FormattedMessage id={modalHeading} />
                </MDBModalHeader>
                <MDBModalBody>
                    <MDBRow>
                        <MDBCol size="12">
                            {renderFilterCriteria({
                                filter: (!isEmpty(curFilter) && curFilter),
                                setFilter: setCurFilterVal,
                                defaultFilter: defaultFilter,
                                setDefaultFilter
                            })}
                            <MDBRow>
                                <MDBCol size="12">
                                    <CheckboxIntl
                                        name="rememberFilterCriteria"
                                        id="rememberFilterCriteria"
                                        containerClass="mx-0 my-3 pl-0"
                                        labelClass="mr-0"
                                        value={saveFilter}
                                        onChange={e => setSaveFilter(e.target.checked)}
                                        label={<FormattedMessage id="invoice.search-field.invoice-save-search-settings" />}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>
                <MDBModalFooter className='mt-4'>
                    <MDBBtn color="primary" onClick={() => {
                        let resultFilter = Object.assign({}, curFilter)
                        setFilter(resultFilter)

                        const parsedFilter = produce(draft => {
                            if (saveParseFuncs) {
                                Object.keys(draft).forEach((key) => {
                                    draft[key] = saveParseFuncs[key](draft[key])
                                })
                            } if (trimMultiSelectAll) {
                                //remove extraneous data when we have selected all with a multi-select field
                                Object.keys(draft).forEach((key) => {
                                    if (Array.isArray(draft[key]) && draft[key]?.[0]?.value === "*") {
                                        draft[key] = [draft[key][0]]
                                    }
                                })
                            }
                        })(resultFilter)
                        setSessionFilter(filterName, parsedFilter, businessUnit, country)
                        if (saveFilter) {
                            dispatch(saveIdentityPreferences({ [filterName]: parsedFilter }))
                        }
                        closeModal()
                    }}>
                        <FormattedMessage id="apply.label" />
                    </MDBBtn>
                    <MDBBtn color="secondary" onClick={() => {
                        closeModal()
                        setCurFilter(defaultFilter)
                        setFilter(defaultFilter)
                        clearSessionFilter(filterName, businessUnit, country)
                    }}>
                        <FormattedMessage id="clear.label" />
                    </MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        </ReactPortalModal>)
    } else {
        return null;
    }
}