import {useSelector, useDispatch} from "react-redux";

import {registerField, deregisterField, submitValidation, updateValidation} from "../../actions/validation-action";
import {StandardValidations as defaultValidations} from "./standard-validations.js";

// Validate a field with a provided value
export function validate({vFields, actions}, fieldName, value) {
    let messages = [];
    const validationList = vFields[fieldName]?.getValidations();
    if (!validationList) return [];

    const isOptional = !validationList.find(v => Array.isArray(v)
        && v.length === 2
        && v[0] === 'required'
    );

    if (!isOptional || value !== '') validationList.forEach(v => {
        if (typeof v === 'function') {
            // function that will return the error message if invalid, and a blank string if valid
            const resultMsg = v(value);
            if (resultMsg) messages.push(resultMsg);
            else if (resultMsg !== '') messages.push(`No validation message found`);
        } else if (Array.isArray(v) && v.length === 2) {
            if (defaultValidations[v[0]]) {
                if (!defaultValidations[v[0]](value)) messages.push(v[1]);
            } else {
                messages.push(`No validation message for ${v[0]}`);
            }
        } else if (Array.isArray(v) && v.length === 3) {
            if (defaultValidations[v[0]]) {
                if (!defaultValidations[v[0]](value, v[2])) messages.push(v[1]);
            } else {
                messages.push(`No validation message for ${v[0]}`);
            }
        }
    });

    const isValid = messages.length === 0;
    if (!isValid) messages = [messages[0]]; // UPS asked for one error at a time per field
    actions.updateValidation({
        fieldName,
        result: {status: (isValid ? 'valid' : 'error'), messages}
    });
    return messages;
}

// Get a field's value and validate it
export function validateGetValue(context, fieldName) {
    return validate(context,
        fieldName,
        context.vFields[fieldName]?.getValue()
    );
}

// Validate all fields currently in Redux store
export function validateAll({vFields, actions}) {
    let messages = [];
    Object.keys(vFields).forEach(fieldName => {
        const fieldMessages = validateGetValue({vFields, actions}, fieldName);
        if (fieldMessages) messages = messages.concat(fieldMessages);
    });
    actions.submitValidation();
    return {messages};
}

export function register({actions}, data){
    actions.registerField(data)
}

export function deregister({actions}, fieldName){
    actions.deregisterField({fieldName})
}

// Custom hook
// Usage:
// const validator = useValidator();
// ...
// const result = validator.validateAll();
export function useValidator() {
    const {vFields, vState} = useSelector(s => s.validation);
    const dispatch = useDispatch();
    const ctx = {vFields, actions: {
        updateValidation: (...args) => dispatch(updateValidation(...args)),
        submitValidation: (...args) => dispatch(submitValidation(...args)),
        registerField: (...args) => dispatch(registerField(...args)),
        deregisterField: (...args) => dispatch(deregisterField(...args))
    }};
    return {
        validate: (field, value) => validate(ctx, field, value),
        validateAll: () => validateAll(ctx),
        validateGetValue: field => validateGetValue(ctx, field),
        register: (fieldName, refs) => register(ctx, {
            fieldName,
            getValue: () => refs.current.value,
            getValidations: () => refs.current.validations,
            getContext: () => ({label: refs.current.label})
        }),
        deregister: (field) => deregister(ctx, field),
        getVState: (field) => vState[field]
    };
}