import React, { Component } from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { withRouter } from 'react-router-dom';
import Exception from '../../Exception/index'
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem  } from "mdbreact";
import { isEmpty as lodashIsEmpty, get as lodashGet} from 'lodash';
import produce from 'immer';
import CustomDateRangePicker from "../../../components/UPS/CustomDateRangePicker";
import CalendarInfoPanel from "../../../components/UPS/CalendarInfoPanel";

import ExportDataModal from '../../../components/UPS/Modal/ExportDataModal';
import PlanInvoiceSearchCriteriaModal from "../Modal/PlanInvoiceSearchCriteriaModal";
import DownloadAllDocumentModal from '../../../components/UPS/Modal/DownloadAllDocumentModal';

import * as identityActions from "../../../actions/identity-action";
import * as invoiceActions from "../../../actions/invoice-action";
import * as invoiceStatuses from '../../../constants/invoice-statuses';
import {FormattedMessage, injectIntl} from 'react-intl';
import moment from 'moment';
import {
    formatDateRangeString,
    getSavedPreferences,
    filterInvoices,
    sanitizePlanInvoiceFilter,
    getDownloadEligibility,
    generateStatementList,
    isInvoiceXmlDownloadEligible,
    handleInvoiceDownload, getInvoiceDownloadErrorMsg
} from '../../../utils/invoice-utils';
import {defaultButtonProps, exportData, SORTED_DSC} from "../../DataTableV3/DataTableV3-utils";
import FilteredDataTableV3, {INITIAL_TABLE_STATE} from "../../DataTableV3/DataTableV3FilterWrapper";
import memoize from "memoize-one";
import { ReactComponent as PdfSvg } from '../../../assets/images/svg/pdf.svg'
import {getKeyByBU, stripAccountNumber, removeUrlQueryParams} from "../../../utils/ups-utils";
import {getDefaultPlanInvoiceColumns,getrowHeaderField, savedColumnCompatibility, formatAccountNumberFilter, formatPlanNumberFilter} from "./PlanInvoiceTable-utils";
import ColumnSettingsModal from "../Modal/ColumnSettingsModal";
import {calendarIcon} from "../../../assets/images/icon/datatableIcons";
import {getSessionFilter, setSessionFilter} from "../../../utils/utils";
import Spinner from "../../Spinner";
import * as R from "ramda";
import NotificationModal from "../Modal/NotificationModal";

class PlanInvoiceTable extends Component {
    constructor(props) {
        super(props);
        
        const { preferences, businessUnit, businessUnitConfigs, filters, calendarOption, currencyCode, countryCode} = this.props;
        const savedSearchCriteriaType = businessUnitConfigs('planInvoiceTableSearchCriteria')
        const savedColumnsType = businessUnitConfigs('planInvoiceTableSavedColumns')
        const sessionFilter = getSessionFilter('planInvoiceTableSearchCriteria', businessUnit, countryCode)

        let planInvoiceFilters = filters;
        if (!lodashIsEmpty(filters) && filters.invoiceFilters) {
            planInvoiceFilters = { ...filters.invoiceFilters, ...(filters.invoiceFilters.hasOwnProperty("dueDate") ? { calendarOptionType: "CUSTOM", dateType: "2" } : {}) };
        }

        let { dateType = "1", selectedStartDate = null, selectedEndDate = null, calendarOptionType = 'AVAILABLE'} = lodashIsEmpty(filters) ? getSavedPreferences(sessionFilter ? {[savedSearchCriteriaType]: sessionFilter} : preferences, savedSearchCriteriaType) : {};
        let initialFilter = {
            ...(lodashIsEmpty(filters) ? (sessionFilter ?? lodashGet(preferences, savedSearchCriteriaType, {})) : filters),
            ...(businessUnit === "SCS" && { currencyCode }),
            businessUnit,
            ...(filters.invoiceFilters ? filters.invoiceFilters: {}),
            ...(calendarOption ? calendarOption: {}),
            filterType: filters?.invoiceFilters ? "EMAIL" : ''
        }

        delete initialFilter.planInvoiceNumber;
        delete initialFilter.invoiceNumber;

        initialFilter.planNumber = formatPlanNumberFilter(initialFilter.planNumber, businessUnit);
        initialFilter.accountNumber = formatAccountNumberFilter( initialFilter.accountNumber, businessUnit);

        if (!lodashIsEmpty(planInvoiceFilters) && planInvoiceFilters.hasOwnProperty("dueDate")) {
            const { dueDate } = planInvoiceFilters;
            dateType = "2";
            calendarOptionType = "CUSTOM";
            selectedStartDate = moment(dueDate.dateBegin);
            selectedEndDate = moment(dueDate.dateEnd);
        }
        if (!lodashIsEmpty(calendarOption) && calendarOption.hasOwnProperty("calendarOptionType")) {
            
            dateType = calendarOption.dateType;
            calendarOptionType = calendarOption.calendarOptionType;
        }

        this.state = {
            loaded: false,
            error: '',
            data: null,
            dataPath: '',
            planInvoiceList: [],
            tableState: INITIAL_TABLE_STATE,
            exportDataModal: false,
            downloadAllDocumentModal: false,
            filterModal: false,
            columnSettingsModal: false,
            showDatePickerModal: false,
            selectedStartDate, //currently selected values within calendar modal
            selectedEndDate,
            selectedCalendarOptionType: calendarOptionType,
            selectedDateType: dateType,
            calendarOptionType, //currently set calendar settings on the table
            calendarStartDate: { ...selectedStartDate },
            calendarEndDate: { ...selectedEndDate },
            calendarDateType: dateType,
            savedSearchCriteriaType,
            savedColumnsType,
            filter: initialFilter,
            isFilterEnabled: this.isFilterEnabled(initialFilter),
            notificationModalProps: {}
        };
    }

    componentDidMount() {
        const { invoiceActions, filterPayable, invoiceType } = this.props;
        const { filter } = this.state; 
        
        if(filterPayable){
            const filters = {
                accounts: false,
                plans: true,
                keyToAccessInvoices: invoiceType,
                businessUnit: this.props.selectedPaymentType,
                [invoiceType]: true
            };
            
            invoiceActions.getPayableInvoices(filters, (response) => {
                const payableInvoices = response[filters.keyToAccessInvoices].invoices;
                this.setInvoices(payableInvoices);
            })
        }
        else{
            invoiceActions.getPlanInvoiceList(filter, (list)=>{
                this.setInvoices(list);
            })
        }
    }

    setInvoices = (list) => {
        const {invoiceFilters} = this.props.filters;

        if(!list?.length){
            list = [];
        }
        if (invoiceFilters) {
            removeUrlQueryParams();
            if(list.length > 0){
                list = filterInvoices(list, invoiceFilters);
            }
        }

        this.updateStateInvoices(list);
    }

    isFilterEnabled = (filter = this.state.filter) => {
        const exists = (prop) => (!!filter?.[prop] && !lodashIsEmpty(filter?.[prop]))
        //calendar is not included in this consideration because it has its own filter indicator on the calendar button
        return exists('accountNumber') || exists('invoiceStatus') || exists('invoiceType') || exists('planNumber')
    }

    toggleModal = (modalName, data) => {
        this.setState({ data, [`${modalName}Modal`]: !this.state[`${modalName}Modal`] });
    };

    getDateRangeString() {
        const { intl } = this.props;
        const { calendarOptionType, calendarStartDate, calendarEndDate } = this.state;
        return formatDateRangeString(intl, calendarOptionType, calendarStartDate ? moment(calendarStartDate) : '', calendarEndDate ? moment(calendarEndDate) : '')
    }

    handleCalendarSearch = (calendarState) => {
        const { showDatePickerModal, filter, selectedStartDate, selectedEndDate, selectedCalendarOptionType, savedSearchCriteriaType, selectedDateType } = this.state;
        const { identityActions, businessUnit, countryCode } = this.props;
        const { saveSearchCriteria } = calendarState;

        if (selectedCalendarOptionType === 'CUSTOM' && !(selectedStartDate && selectedEndDate)) return;
        const newFilter = {
            ...filter,
            calendarOptionType: selectedCalendarOptionType,
            [selectedDateType === "1" ? 'invoiceDate' : 'dueDate']: selectedStartDate ? {
                dateBegin: moment(selectedStartDate).startOf('day').format('YYYY-MM-DD') ,
                dateEnd: moment(selectedEndDate).endOf('day').format('YYYY-MM-DD')
            } : null,
            [selectedDateType !== "1" ? 'invoiceDate' : 'dueDate']: null,
            dateType: selectedDateType,
            ...(filter.filterType === "EMAIL" ? {filterType: ""} : {})
        }
        this.setState({
            filter: newFilter,
            calendarStartDate: selectedStartDate,
            calendarEndDate: selectedEndDate,
            calendarOptionType: selectedCalendarOptionType,
            calendarDateType: selectedDateType,
            isFilterEnabled: this.isFilterEnabled(newFilter)
        }, () => {
            setSessionFilter('planInvoiceTableSearchCriteria', sanitizePlanInvoiceFilter(newFilter), businessUnit, countryCode)
            if (saveSearchCriteria) identityActions.saveIdentityPreferences({[savedSearchCriteriaType]: sanitizePlanInvoiceFilter(newFilter)});
            this.doPlanInvoiceSearch();
        });
        if (showDatePickerModal) this.showDatePicker();
    }

    runFilter = (newFilter, isClearFilter) => {
        const {businessUnit, countryCode} = this.props
        const fullNewFilter = {...newFilter, filterType: ''}
        setSessionFilter('planInvoiceTableSearchCriteria', fullNewFilter, businessUnit, countryCode)
        this.setState(produce(newState=>{
                newState.filter = fullNewFilter
                newState.isFilterEnabled = this.isFilterEnabled(newFilter)
                if(isClearFilter) newState.tableState.searchQuery = ''
            }),
            ()=>this.doPlanInvoiceSearch()
        )
    }

    clearFilter = () => this.runFilter({...this.state.filter, accountNumber: '', invoiceStatus: '', invoiceType: '', planNumber: '', planInvoiceNumber: '', accounts: '', debitOnly:'', invoiceTypeNotIn:'', paymentusPaymentDataStatusNotIn:''}, true)

    doPlanInvoiceSearch = (filter = this.state.filter) => {
        const { invoiceActions } = this.props;
        invoiceActions.getPlanInvoiceList({...filter}, (invoices) => this.updateStateInvoices(invoices));
    }

    updateStateInvoices = (invoices) => {
        const { sessionSelection, businessUnit } = this.props;
        const { filter } = this.state;
        
        this.setState(produce(draft=> {
            draft.loaded = true
            draft.planInvoiceList = invoices
            // apply date filters for date range received in query params in case of payment reminder & past due.
            if (!lodashIsEmpty(filter) && filter.invoiceFilters) {
                const { invoiceFilters } = filter;
                // SCS has only 1 date type i.e. statement date that refers to option 1.
                const dateObj = { calendarOptionType: "CUSTOM", dateType: businessUnit === "SCS"? "1" : "2" };
                let initialFilter = {
                    ...invoiceFilters,
                    ...(businessUnit === "SCS" && { currencyCode: sessionSelection.merchant?.currencyCode }),
                    businessUnit,
                    ...(invoiceFilters.hasOwnProperty("dueDate") ? dateObj : {}),
                    filterType: "EMAIL"
                }

                delete initialFilter.planInvoiceNumber;                
                draft.filter = initialFilter;
                draft.isFilterEnabled = this.isFilterEnabled(initialFilter)

                if (invoiceFilters.hasOwnProperty("dueDate")) {
                    const { dueDate } = invoiceFilters;
                    let selectedStartDate = moment(dueDate.dateBegin), selectedEndDate = (moment(dueDate.dateEnd));
                    draft.calendarDateType = dateObj.dateType;
                    draft.calendarOptionType = dateObj.calendarOptionType;
                    draft.calendarStartDate = { ...selectedStartDate };
                    draft.calendarEndDate = { ...selectedEndDate };
                }
            }
        }))
    }

    createDatatableRowActions = (data) => {
        const {intl,permissions, businessUnitKeys, businessUnit, invoiceMetadata, countryCode} = this.props;
        
        const invoiceNumber = businessUnit === "EBS" ? stripAccountNumber(data.planNumber, "EBS") : data.planInvoiceNumber;
        const actionLabel = intl.formatMessage({id:businessUnitKeys('moreActionsLabel')}, {invoiceNumber});
        let downloadOptions = null;
        if (permissions?.invoice_download) {
            const downloadEligibility = getDownloadEligibility(businessUnit, data.recordType, data.localCsvIr === "1");
            const xmlIsEnrolled = downloadEligibility.includes('xml') && (data.xmlIr === "1" || data.xmlIr === "undefined")
            const documentList = generateStatementList(data, intl, invoiceMetadata, true, businessUnit,  xmlIsEnrolled, downloadEligibility)
            downloadOptions = documentList.map(({type,url})=>{
                return <MDBDropdownItem
                    key={`dropdown-download-plan-inv-${data.invoiceNumber}-${type}`}
                    id={`dropdown-download-plan-inv-${data.invoiceNumber}-${type}`}
                    onClick={() => this.downloadPlanInvoiceAction(data,type,url)} download
                >
                    <FormattedMessage id={`btn.download-option-action-dropdown.${type?.toUpperCase()}`} values={{var:['US','CA'].includes(countryCode.toUpperCase()) ? '32' : '28'}}/>
                </MDBDropdownItem>
            })
        }

        return (
            <MDBDropdown>
                <MDBDropdownToggle color="primary" className="custom-vertical-dropdown" aria-label={actionLabel}>
                    <i className="fas fa-ellipsis-v"></i>
                </MDBDropdownToggle>
                <MDBDropdownMenu basic right>
                    <MDBDropdownItem onClick={() => this.viewPlanSummary(data)}>
                        {intl.formatMessage({id: businessUnitKeys('viewDrillDownMenuButton')})}
                    </MDBDropdownItem>
                    {permissions?.invoice_download && downloadOptions}
                </MDBDropdownMenu>
            </MDBDropdown>
        )
    };

    downloadDocument = (data) => {
        this.toggleModal('downloadAllDocument', data);
    };

    viewPlanSummary = (invoice) => {
        this.props.history.push({pathname: '/ups/billing/invoice', state: { prevPath: this.props.location.pathname, invoice, isDrillMode: true}})
    };

    showDatePicker = () => {
        if (this.state.calendarOptionType === 'CUSTOM') {
            this.setState({ showDatePickerModal: !this.state.showDatePickerModal, selectedCalendarOptionType: this.state.calendarOptionType, selectedStartDate: moment(this.state.calendarStartDate), selectedEndDate:moment(this.state.calendarEndDate) });
        } else {
            this.setState({ showDatePickerModal: !this.state.showDatePickerModal, selectedCalendarOptionType: this.state.calendarOptionType, selectedStartDate: null, selectedEndDate:null  });
        }
    }

    handleCalendarChange = (calendarOpts) => {
        const { calendarOptionType, dateType } = calendarOpts;
        if(dateType){
            this.setState({selectedDateType: dateType})
        } else {
            this.setState({
                selectedStartDate: null,
                selectedEndDate: null,
                selectedCalendarOptionType: calendarOptionType ?? 'AVAILABLE'
            });
        }
    }

    getCalendarButtonText(){
        return <><span><span id="datatable-date-selection" className="datatable-date-selection">{this.getDateRangeString()}</span> {calendarIcon}</span></>
    }

    getActionColumn = () => {
        const {intl} = this.props;
        return {
            field: 'actions',
            label: intl.formatMessage({ id: 'invoice.invoice-table.actions' }),
            className: 'text-center datatable-action-col prevent-toggle no-export',
            sortable: false,
            responsivePriority: 2,
            display: this.createDatatableRowActions
        }
    }

    getPDFColumn = () => {
        const {invoiceMetadata, intl} = this.props
        return ({
            field: "PDF",
            label: intl.formatMessage({id:'ups.invoice.download.table.pdf'}),
            tdClassName:"noPadding",
            thClassName:"rightPadding",
            display: (data) => <div className="pointer"><PdfSvg width={28} height={28} onClick={()=>{
                this.downloadPlanInvoiceAction(data,"pdf",invoiceMetadata?.invoiceDownloads?.pdf)
            }}/></div>,
            sortable:false,
            responsivePriority:4,
            noExport: true,
            hideable: false
        })
    }

    createDatatableCols = memoize(() => {
        const { savedColumnsType } = this.state;
        const { preferences, permissions } = this.props;
        const defaultColumns = getDefaultPlanInvoiceColumns(this.props, this.toggleModal, this.viewPlanSummary)
        const savedColumns = lodashGet(preferences, `${savedColumnsType}.savedColumns`);
        const customizableCols = (savedColumns && !lodashIsEmpty(savedColumns)) ? savedColumns.map(data=>{
            return defaultColumns[savedColumnCompatibility((typeof data === 'object') ? data.name : data)]
        }).filter((column)=>!!column) : Object.values(defaultColumns)
        return [
            ...customizableCols,
            ...permissions?.invoice_download ? [this.getPDFColumn()] : [],
            this.getActionColumn()
        ];
    });

    renderDateRangePicker = ()=>{
        const {showDatePicker, handleCalendarSearch, handleCalendarChange} = this;
        const {intl, businessUnit} = this.props;
        const {selectedStartDate, selectedEndDate, filter, showDatePickerModal, selectedCalendarOptionType} = this.state;
        return <CustomDateRangePicker
            startDate={selectedStartDate} // momentPropTypes.momentObj or null,
            endDate={selectedEndDate} // momentPropTypes.momentObj or null,
            handleDateChange={({startDate, endDate}) => this.setState({ selectedStartDate: startDate, selectedEndDate: endDate, selectedCalendarOptionType: 'CUSTOM' })}
            autoFocusEndDate={false}
            calendarInfoPosition="after"
            showDefaultInputIcon
            showDatePicker={showDatePicker}
            showDatePickerTest={showDatePickerModal}
            renderCalendarInfo={() => (
                <CalendarInfoPanel
                    handleSearch={(filter)=>{
                        handleCalendarSearch(filter)
                    }}
                    showDatePicker={showDatePicker}
                    filters={filter}
                    intl={intl}
                    calendarOptionType={selectedCalendarOptionType}
                    handleCalendarChange={handleCalendarChange}
                    businessUnit={businessUnit}
                />
            )}
            dateRangeString={this.getDateRangeString()}
        />
    }

    downloadPlanInvoiceAction = async (invoice, type, url) => {
        const {intl, businessUnit, userlocale, invoiceMetadata, adUserId, countryCode} = this.props;
        this.setState({spinning:true})
        if(type==="XML"){
            const eligible = await isInvoiceXmlDownloadEligible(invoice,countryCode,businessUnit,adUserId, true)
            if(!eligible) {
                this.setState({
                    notificationModalProps: {
                        message: "invoice.downloadInvoices.xml.notEligible.body",
                        title: "invoice.downloadInvoices.xml.notEligible.title",
                        alert:true
                    },
                    spinning:false
                })
                return
            }
        }
        handleInvoiceDownload(url, type, invoice, intl, true, businessUnit, userlocale)
            .catch((response)=>{
                const {errorMsg, errorTitle} = getInvoiceDownloadErrorMsg(response, invoiceMetadata)
                this.setState({notificationModalProps: {
                        message: errorMsg,
                        title: errorTitle,
                        alert:true
                    }})
            })
            .finally(()=>{
                this.setState({spinning:false})
            })
    }

    render() {
        const { toggleModal, showDatePicker } = this;
        const { defaultButtonProps, preferences, intl, currencyCode, caption } = this.props;
        const { planInvoiceList, data, savedColumnsType, savedSearchCriteriaType,
                exportDataModal, downloadAllDocumentModal, filterModal, columnSettingsModal,
                loaded, error, isFilterEnabled, tableState, filter, notificationModalProps } = this.state;
        const { filteredData } = tableState;

        const savedColumns = lodashGet(preferences, `${savedColumnsType}.savedColumns`)
        //this memoized function will only rerun on a render when the input arguments change
        const columns = this.createDatatableCols(intl.locale, savedColumns)
        const rowHeaderField = getrowHeaderField(this.props);

        if (!loaded) return null;

        if (error instanceof TypeError) {
            return (<Exception error={error} />)
        } else {
            return <>
                {this.renderDateRangePicker()}
                <FilteredDataTableV3
                    caption={caption}
                    name={'plan-invoice-table'}
                    className={'table-bordered-rows'}
                    trClassName={({invoiceStatus})=>(invoiceStatus === invoiceStatuses.PAST_DUE)?'past-due-border-left':''}
                    data={planInvoiceList}
                    columns={columns}
                    buttons={[
                        {type: "filter", action:()=>toggleModal('filter')},
                        {...defaultButtonProps.columnSettings, action:()=>toggleModal('columnSettings')},
                        {...defaultButtonProps.calendar, text: this.getCalendarButtonText(), action:showDatePicker},
                        { type: "print" },
                        { ...defaultButtonProps.export, action: () => toggleModal('exportData') }
                    ]}
                    defaultSorting={({invoiceStatus, invoiceDate})=>(`${invoiceStatus === invoiceStatuses.PAST_DUE?'1':'0'}${invoiceDate}`)}
                    defaultSortDirection={SORTED_DSC}
                    externalFilterActive={isFilterEnabled}
                    onClearFilter={this.clearFilter}
                    tableState={tableState}
                    tableStateAction={(action)=>this.setState(action)}
                    searchable
                    delayedSearch={planInvoiceList.length > 1000}
                    printable
                    responsive
                    rowHeaderField={rowHeaderField}
                    filterType={filter.filterType}
                />
                <ExportDataModal
                    isOpen={exportDataModal}
                    toggleModal={toggleModal}
                    handleExport={(type)=>exportData(filteredData,columns,tableState,document.title,type)}
                    an_label={'export-account-invoices-data'}
                />
                {filterModal && (
                    <PlanInvoiceSearchCriteriaModal
                        isOpen={filterModal}
                        toggleModal={toggleModal}
                        runFilter={this.runFilter}
                        clearFilter={this.clearFilter}
                        planInvoiceFilters={filter}
                        savedPreferenceType={savedSearchCriteriaType}
                        currencyCode={currencyCode}
                    />
                )}
                <ColumnSettingsModal
                    modalName={'columnSettings'}
                    isOpen={columnSettingsModal}
                    columns={() => getDefaultPlanInvoiceColumns(this.props, toggleModal, this.viewPlanSummary)}
                    columnCompatibilityFunc={savedColumnCompatibility}
                    toggleModal={toggleModal}
                    savedPreferenceType={savedColumnsType}
                />
                {downloadAllDocumentModal && (
                    <DownloadAllDocumentModal
                        isOpen={downloadAllDocumentModal}
                        invoice={data}
                        downloadType={'inv'}
                        toggleModal={toggleModal}
                        showToggleDisplayMode={true}
                        isPlanInvoice={true}
                    />
                )}
                {!R.isEmpty(notificationModalProps) && <NotificationModal
                    isOpen={!R.isEmpty(notificationModalProps)}
                    closeModal={() => this.setState({ notificationModalProps: {} })}
                    {...notificationModalProps}
                />}
                <Spinner isSpinning={this.state.spinning}/>
            </>
        }
    }
}

function mapStateToProps(state, ownProps) {
    return {
        preferences: state.identity.preferences,
        invoiceMetadata: state.invoice.invoiceMetadata,
        permissions: state.auth.user.permissions,
        businessUnit: state.auth.user.selectedPaymentType,
        businessUnitConfigs: getKeyByBU('config')(state.auth.user.selectedPaymentType),
        businessUnitKeys: getKeyByBU('planInvoiceTable')(state.auth.user.selectedPaymentType),
        countryCode: state.auth.user.selectedCountry,
        sessionSelection: state.config?.sessionSelection,
        currencyCode: state.config?.sessionSelection?.merchant?.currencyCode,
        defaultButtonProps: defaultButtonProps(ownProps.intl)
    }
}

function mapDispatchToProps(dispatch) {
    return {
        identityActions: bindActionCreators(identityActions, dispatch),
        invoiceActions: bindActionCreators(invoiceActions, dispatch)
    }
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(PlanInvoiceTable)));