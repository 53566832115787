import React,  { Component } from 'react'
import * as invoiceActions from "../../../../actions/invoice-action";
import * as identityActions from "../../../../actions/identity-action";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol  } from 'mdbreact';
import { FormattedMessage, FormattedDate, injectIntl  } from 'react-intl';
import ReactPortalModal from "../../../ReactPortalModal";
import UPSPlanSearchCriteria from "../../UPSPlanInvoiceSearchCriteria";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {createLookupTable} from "../../../../utils/utils";
import {sanitizePlanInvoiceFilter} from "../../../../utils/invoice-utils";

class PlanInvoiceSearchCriteriaModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...(props.planInvoiceFilters ? props.planInvoiceFilters : {})
        };
    }

    checkMultiSelectAll = (value, hasAll) => {
        return hasAll ? undefined : value //if we have selected every option, we pass undefined, it's the same as "no filter"
    }

    handlePlanSearch = () => {
        const { identityActions, runFilter } = this.props;
        const { toggleModal, planInvoiceFilters, savedPreferenceType } = this.props;
        const { accountNumber, invoiceStatus, invoiceType, planNumber, saveSearchCriteria } = this.state;
        const { invoiceStatusSelectAll, invoiceTypeSelectAll, accountNumberSelectAll,planNumberSelectAll  } = this.state;

        const newFilter = sanitizePlanInvoiceFilter({
            ...planInvoiceFilters,
            invoiceStatus: this.checkMultiSelectAll(invoiceStatus, invoiceStatusSelectAll),
            invoiceType: this.checkMultiSelectAll(invoiceType, invoiceTypeSelectAll),
            accountNumber: this.checkMultiSelectAll(accountNumber, accountNumberSelectAll),
            planNumber: this.checkMultiSelectAll(planNumber, planNumberSelectAll)
        })

        if (saveSearchCriteria) identityActions.saveIdentityPreferences({[savedPreferenceType]: newFilter});

        runFilter(newFilter);
        toggleModal('filter');
    };

    onUpdatePlanFilters = (key, value, allSelection) => {
        let allSelected = false;
        if(allSelection){
            const lookup = createLookupTable(value)
            allSelected = allSelection.reduce((acc, option)=>{
                acc &= lookup[option.value]
                return acc
            }, true)
        }
        this.setState({
            [key]: value,
            [`${key}SelectAll`]: allSelected
        });
    }

    clearFilters = () => {
        const { toggleModal, clearFilter } = this.props;
        clearFilter()
        toggleModal('filter');
    }

    render() {
        const { isOpen, toggleModal, backdrop, intl } = this.props;
        const modalHeading = "invoice.search-title";
        if (isOpen) {
            return (
                <ReactPortalModal isOpen={isOpen} an_label="ups-plan-search-criteria">
                    <MDBModal id={'plan-search-modal'} isOpen={isOpen} toggle={() => toggleModal('filter')} size="lg" backdrop={backdrop} centered disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
                        <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"}, { name: intl.formatMessage({ id: modalHeading }) })} toggle={() => toggleModal('filter')} ><FormattedMessage id={modalHeading}/></MDBModalHeader>
                        <MDBModalBody>
                            <MDBRow>
                                <MDBCol size={"12"}>
                                    <UPSPlanSearchCriteria
                                        modalMode={true}
                                        key={'plan-search-criteria'}
                                        onUpdatePlanFilters={this.onUpdatePlanFilters}
                                        planInvoiceFilters={this.state}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn id={'plan-search-btn-search'} color="primary" onClick={this.handlePlanSearch}><FormattedMessage id={"search.label"}/></MDBBtn>
                            <MDBBtn id={'plan-search-btn-cancel'} color="cancel" onClick={this.clearFilters}><FormattedMessage id={"clear.label"}/></MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </ReactPortalModal>
            )
        } else {
            return null;
        }
    }
}

function mapStateToProps(state, ownProps) {
    return {
        layout: state.layout,
        preferences: state.identity.preferences,
        businessUnit: state.auth.user.selectedPaymentType,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        invoiceActions: bindActionCreators(invoiceActions, dispatch),
        identityActions: bindActionCreators(identityActions, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(PlanInvoiceSearchCriteriaModal)));
