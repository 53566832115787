import moment from 'moment';
import momentTz from 'moment-timezone';
import {padAccountNumbers, padAccountNumber} from "./ups-utils";
import { get as lodashGet, isUndefined as lodashIsUndefined, isEmpty as lodashIsEmpty, trim as lodashTrim, isNil as lodashIsNil } from 'lodash';
import * as invoiceStatuses from "../constants/invoice-statuses";
import * as invoiceTypes from "../constants/invoice-types";
import * as R from 'ramda';
import * as $ from 'jquery';
import * as _ from 'lodash';
import React  from 'react'
import { stripAccountNumber, formatToLocalDate } from './ups-utils';
import { BUSINESS_UNIT } from '../constants/business-unit';
import {isFreightActive, noDocumentDownloadInvoiceTypes} from "./config-utils";
import * as INVOICE_TYPES from "../constants/invoice-types";
import * as invoiceApi from "../api/invoice-api";
import {getInvoiceDownloadPromise, getPlanInvoiceListPromise} from "../api/invoice-api";
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import {INVOICE_PENDING_DOWNLOAD_MSG} from "../constants/invoice-constants";
import * as accountApi from "../api/account-api";

export function createInvoiceFilter(filters) {
    const { merchantId, businessUnit, planNumber, planInvoiceNumber, accountNumber, invoiceStatus, invoiceType, paymentStatus, invoiceDate, dueDate, calendarOptionType, dateType, freightTerms, currencyCode, accounts, isPayable, payableIr, debitOnly, invoiceTypeNotIn, paymentusPaymentDataStatusNotIn } = filters;
    const {invoiceDateRange, dueDateRange} = createDateRangeFilter(invoiceDate, dueDate, calendarOptionType, dateType);
    return {
        ...(merchantId ? { merchantId } : {}),
        ...(businessUnit ? { businessUnit } : {}),
        ...(currencyCode ? { currencyCode } : {} ),
        ...(Array.isArray(planNumber) && planNumber.length > 0 ? {planNumber: padAccountNumbers(planNumber)} : {} ),
        ...(planInvoiceNumber ? { planInvoiceNumber: (Array.isArray(planInvoiceNumber) ? planInvoiceNumber : [planInvoiceNumber]) } : {}),
        ...(Array.isArray(accountNumber) && accountNumber.length > 0 ? businessUnit === "EBS" ? {accountNumber: padAccountNumbers(accountNumber)} : {accountNumber: accountNumber?.map(accNbr=>stripAccountNumber(accNbr, businessUnit))} : {}),
        ...(invoiceStatus ? { invoiceStatus: (Array.isArray(invoiceStatus) ? invoiceStatus : [invoiceStatus]) } : {}),
        ...(freightTerms ? { freightTerms: (Array.isArray(freightTerms) ? freightTerms : [freightTerms]) } : {}),
        ...(invoiceType ? { invoiceType: (Array.isArray(invoiceType) ? invoiceType : [invoiceType]) } : {}),
        ...(paymentStatus ? { paymentStatus: (Array.isArray(paymentStatus) ? paymentStatus : [paymentStatus]) } : {}),
        ...(invoiceDateRange && Object.keys(invoiceDateRange).length > 0 ? {
            invoiceDateBegin: invoiceDateRange.dateBegin,
            invoiceDateEnd: invoiceDateRange.dateEnd
        } : {} ),
        ...(dueDateRange && Object.keys(dueDateRange).length > 0 ? {
            dueDateBegin: dueDateRange.dateBegin,
            dueDateEnd: dueDateRange.dateEnd
        } : {} ),
        ...(isPayable ? { isPayable } : {}),
        ...(payableIr ? { payableIr } : {}),
        ...(debitOnly ? { debitOnly } : {}),
        ...(invoiceTypeNotIn ? { invoiceTypeNotIn: (Array.isArray(invoiceTypeNotIn) ? invoiceTypeNotIn : [invoiceTypeNotIn]) } : {}),
        ...(paymentusPaymentDataStatusNotIn ? { paymentusPaymentDataStatusNotIn: (Array.isArray(paymentusPaymentDataStatusNotIn) ? paymentusPaymentDataStatusNotIn : [paymentusPaymentDataStatusNotIn]) } : {})
    };
}

export function prepareInvoiceSummarizers(filters) {
    const { totals, dueSoon, pastDue, plans, accounts, planAccounts, maxInvoices } = filters;
    return {
        ...(totals ? {totals}: {}) ,
        ...(dueSoon ? {dueSoon} : {}),
        ...(pastDue ? {pastDue} : {}),
        ...(plans ? {plans} : {}),
        ...(accounts ? {accounts} : {}),
        ...(planAccounts ? {planAccounts} : {}),
        ...(maxInvoices ? {maxInvoices}: {})
    };
}

function createDateRangeFilter(invoiceDate, dueDate, calendarOptionType, dateType) {
    let filterStartDate, filterEndDate, invoiceDateRange, dueDateRange;

    // For Custom date range, the invoice date or due date range is passed through, there's no need to process further
    if (invoiceDate || dueDate) {
        return {invoiceDateRange: invoiceDate, dueDateRange: dueDate};
    }

    if (calendarOptionType && dateType) {
        if (calendarOptionType === 'TODAY') {
            filterStartDate = moment();
            filterEndDate = moment();
        } else {
            let calendar_split = calendarOptionType.split("_");
            if (calendar_split[0] == 'YEAR') {
                filterStartDate = moment().startOf('year');
                filterEndDate = moment();
            } else if (calendar_split.length == 3) {
                let amount = parseInt(calendar_split[1]) - 1
                if (calendar_split[2] == 'DAYS') {
                    if (calendar_split[0] == 'NEXT') {
                        filterStartDate = moment();
                        filterEndDate = moment().add(amount, 'days');
                    } else {
                        filterStartDate = moment().subtract(amount, 'days');
                        filterEndDate = moment();
                    }
                } else {
                    if (calendar_split[0] == 'NEXT') {
                        filterStartDate = moment();
                        filterEndDate = moment().add(amount, calendar_split[2]).endOf(calendar_split[2]);
                    } else {
                        filterStartDate = moment().subtract(amount, calendar_split[2]).startOf(calendar_split[2]);
                        filterEndDate = moment();
                    }
                }
            }
        }
    }

    if (!filterStartDate) {
        invoiceDateRange = {};
        dueDateRange = {};
    } else {
        filterStartDate = filterStartDate.startOf('day').format('YYYY-MM-DD');
        filterEndDate = filterEndDate.endOf('day').format('YYYY-MM-DD');

        if (dateType == 1) {
            invoiceDateRange = {
                dateBegin: filterStartDate,
                dateEnd: filterEndDate
            };

            dueDateRange = {};
        } else {
            dueDateRange = {
                dateBegin: filterStartDate,
                dateEnd: filterEndDate
            };

            invoiceDateRange = {};
        }
    }

    return { invoiceDateRange, dueDateRange };
}

export const getPaymentStatusMessage = (intl, paymentStatus) => intl.formatMessage({ id: `invoice.payment-status.${paymentStatus}` });
export const getInvoiceStatusMessage = (intl, invoiceMetadata, invoiceStatus) => (invoiceMetadata.invoiceStatuses[invoiceStatus]?.msgId
    ? intl.formatMessage({ id: `${invoiceMetadata.invoiceStatuses[invoiceStatus].msgId}` })
    : '') //prevent crashes in the case of missing invoice metadata for new statuses
export const getInvoiceTypeMessage = (intl, invoiceMetadata, invoiceType, countryCode) => {
    const _invoiceType = invoiceMetadata.invoiceTypes[invoiceType];
    if (_invoiceType?.countryMsgIds && countryCode) {
        const msgId = _invoiceType?.countryMsgIds.find(({ countryCode: countryCodes }) => {
            return countryCodes.includes(countryCode)
        })?.msgId
        return intl.formatMessage({ id: msgId || _invoiceType?.msgId })
    } else if(_invoiceType?.msgId){
        return intl.formatMessage({ id: `${_invoiceType?.msgId}` });
    } else return '' //prevent crashes in the case of missing invoice metadata for new types
};

export const getDisplayCurrency = (currencyCode) => {
    return (currencyCode === 'CNY') ? 'RMB' : (currencyCode ?? 'USD') // TODO: Remove default currency code
}

export const formatCurrency = (intl, amount, currencyCode, currencyDisplay="symbol") => {
    return intl.formatNumber(amount, { style: 'currency', currency: getDisplayCurrency(currencyCode), currencyDisplay: currencyDisplay });
}
export const formatNumber = (intl, value) => intl.formatNumber(value);

/**
 * Formats a date string to locale formatting after dropping timestamp and timezone information (if present and ISO8601).
 *
 * Used to format invoice related dates that are not timezone specific.
 *
 * @param {*} intl
 * @param {*} dateString
 */
export function formatInvoiceDate(intl, dateString) {
    if (!intl) return dateString;
    if (!dateString) return dateString;
    const { locale='en-US' } = intl;

    const dateWithNoTimestamp = dateString.split('T')[0];
    if (['9999-12-31', '2099-12-31', '1970-01-01'].includes(dateWithNoTimestamp)) return '';

    const date = moment(dateWithNoTimestamp).toDate();

    if( locale === 'en-CA' || locale === 'fr-CA') return intl.formatDate(date, { month: 'long', day: '2-digit', year: 'numeric' });
    return intl.formatDate(date, { month: 'short', day: '2-digit', year: 'numeric' });
}


export function formatDateRangeString (intl, calendarOptionType, startDate, endDate) {
    const calendarOptionTypeString = calendarOptionType ? `${calendarOptionType}` : "AVAILABLE";

    if (calendarOptionTypeString.toUpperCase() === 'CUSTOM') {
        const startDateString = startDate && formatToLocalDate(intl, moment.isMoment(startDate) ? startDate.format():  startDate); // startDate.format("MMM D, YYYY");
        const endDateString = endDate && formatToLocalDate(intl, moment.isMoment(endDate) ? endDate.format():  endDate);
        return `${startDateString} - ${endDateString}`
    } else {
        return intl.formatMessage({ id: `calendar.option-range.${calendarOptionTypeString}`});
    }
}

/**
 * Helper function that takes in a field type and a value and returns a formatted display value.
 *
 * @param {*} fieldType
 * @param {*} value
 */
export function getFormattedValue(invoiceReference, fieldDefinition) {
    const { intl, invoiceLiterals, invoiceMetadata, currencyDisplay } = this;
    const { type: fieldType = 'text', field: fieldName, template, hideValues = [], delimiter, priorityFieldOrder=[] } = fieldDefinition;
    if(template && typeof fieldName === "string"){
        const value = R.path(fieldName.split('.'), invoiceReference)
        if (R.isNil(value) || R.isEmpty(value) || R.includes(value, hideValues)) return;
    }
    const value = template
        ? interpolateTemplate(template, invoiceReference, invoiceLiterals)
        : R.path(fieldName.split('.'), invoiceReference);

    if (priorityFieldOrder.length > 0) {
        let fieldValue = '';
        for (const index in priorityFieldOrder) {
            let currentFieldValue = R.path(priorityFieldOrder[index].split('.'), invoiceReference);
            if (currentFieldValue > 0) {
                fieldValue = currentFieldValue;
                break;
            }
        }
        return fieldValue;
    }

    if (R.isNil(value) || R.isEmpty(value) || R.includes(value, hideValues)) return;

    if (fieldType === 'date') {
        if (R.not(R.isNil(hideValues))) {
            if (hideValues.includes(moment(value.split('T')[0]).format('YYYY-MM-DD'))) return;
        }
        return formatInvoiceDate(intl, value);
    } else if (fieldType === 'currency') {
        const currencyCode = invoiceReference?.currencyCode || invoiceReference?.invoiceHeader?.currencyCode || "USD"
        return formatCurrency(intl, value, currencyCode, currencyDisplay);
    } else if (fieldType === 'literal') {
        return value
               .split(delimiter)
               .map((value) => invoiceLiterals?.[value])
               .join(" ")
    } else if (fieldType === 'invoiceType') {
        return getInvoiceTypeMessage(intl, invoiceMetadata, invoiceReference.invoiceType)
    } else if (fieldType === 'invoiceStatus') {
        return getInvoiceStatusMessage(intl, invoiceMetadata, invoiceReference.invoiceStatus)
    } else {
        return value;
    }
};

export const interpolateTemplate = (str, obj, invoiceLiterals) => {
    if (!str) return '';
    let replacedStr = str;
    replacedStr = replacedStr.replace(/\%{(.*?)}/g, (x,g) => invoiceLiterals[lodashGet(obj, g, '')] || '');
    replacedStr = replacedStr.replace(/\${(.*?)}/g, (x,g) => lodashGet(obj, g, ''))
    return replacedStr;
}

export function getLabel(invoice, fieldDefinition) {
    const { intl, invoiceLiterals } = this;
    if (!fieldDefinition.msgId) {
        return '';
    } else if (typeof fieldDefinition.msgId === 'string') {
        return intl.formatMessage({ id: fieldDefinition.msgId })
    } else if (typeof fieldDefinition.msgId === 'object') {
        return invoiceLiterals[lodashGet(invoice, fieldDefinition.msgId.field)];
    }
}

export function getSavedPreferences(preferences, savedPreferenceType) {
    let selectedStartDate = null, selectedEndDate = null, dateType = '1', calendarOptionType = 'AVAILABLE';
    if (preferences && preferences[savedPreferenceType]) {
        const { dueDate, invoiceDate} = preferences[savedPreferenceType];
        calendarOptionType = preferences[savedPreferenceType].calendarOptionType;
        dateType = preferences[savedPreferenceType].dateType;

        if (calendarOptionType === 'CUSTOM' && dateType === '1' && invoiceDate) {
            selectedStartDate = moment(invoiceDate.dateBegin);
            selectedEndDate = moment(invoiceDate.dateEnd);
        } else if (calendarOptionType === 'CUSTOM' && dateType === '2' && dueDate) {
            selectedStartDate = moment(dueDate.dateBegin);
            selectedEndDate = moment(dueDate.dateEnd);
        }
    }

    if (calendarOptionType === 'CUSTOM' && (!selectedStartDate || !selectedEndDate)) {
        calendarOptionType = 'AVAILABLE';
    }

    return {
        selectedStartDate,
        selectedEndDate,
        dateType,
        calendarOptionType
    };
}

/**
 * Evaluates an array of conditionals, if any of the elements of the array are valid, then the condiiional is considered
 * passed.
 *
 * @param conditionals
 * @param invoice
 * @returns {boolean}
 */
export function evaluateConditionals(conditionals, invoice) {
    if (!conditionals || conditionals.length === 0) {
        return true;
    }

    let passed = false;
    for (let i = 0; i < conditionals.length; i++) {
        if (evaluateConditional(conditionals[i], invoice)) {
            passed = true;
            break;
        }
    }
    return passed;
}

/**
 * Evaluates a single conditional, assumes that the conditional has properties that map to invoice fields.
 * In order for the conditional to be considered passed, each property must fulfill the condition set for
 * that field.
 *
 * @param conditional
 * @param invoice
 * @returns {boolean}
 */
export function evaluateConditional(conditional, invoice) {
    let passed = true;
    if (!conditional || Object.keys(conditional).length === 0) {
        return passed;
    }

    for (let [key, value] of Object.entries(conditional)) {
        const fieldValue = lodashGet(invoice, key, null);
        const conditionalValue = value.value;
        const conditionalOperation = lodashGet(value, 'operation', '=');
        if (Array.isArray(conditionalValue)) {
            if (['=', 'in'].includes(conditionalOperation)) {
                passed = conditionalValue.includes(fieldValue);
            } else if (['!=', 'not in'].includes(conditionalOperation)) {
                passed = !conditionalValue.includes(fieldValue);
            } else {
                passed = false;
            }
        } else {
            if (conditionalOperation === '=') {
                passed = conditionalValue === fieldValue;
            } else if (conditionalOperation === '!=') {
                passed = conditionalValue !== fieldValue;
            } else if (conditionalOperation === 'startsWith') {
                passed = fieldValue && fieldValue.startsWith(conditionalValue);
            } else if (conditionalOperation === 'gt') {
                passed = fieldValue > conditionalValue;
            } else if (conditionalOperation === 'lt') {
                passed = fieldValue < conditionalValue;
            } else if (conditionalOperation === 'gte') {
                passed = fieldValue >= conditionalValue;
            } else if (conditionalOperation === 'lte') {
                passed = fieldValue <= conditionalValue;
            } else if (conditionalOperation === 'date-lt') {
                passed = moment(fieldValue).isBefore(moment(conditionalValue));
            }  else {
                passed = false;
            }
        }

        if (!passed) {
            break;
        }
    }

    return passed;
}

export function openTab(url, queryString) {
    const newWindow = window.open(`${url}${queryString ? queryString : ''}`,
        '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
}

export function getTranslatedOption(option, fieldType) {
    const { intl, invoiceLiterals } = this;

    let value = option;
    let text = option;
    if (typeof option === 'object') {
        value = option.value;
        if (option.msgId) {
            text = intl.formatMessage({ id: option.msgId})
        } else if (option.text) {
            text = option.text;
        } else {
            text = option.value;
        }
    }

    if (fieldType == 'literal') {
        text = lodashGet(invoiceLiterals, value, value);
    }

    return { value, text };
}
export const isPlanInvoice = invoice => !!invoice.planInvoiceNumber && !invoice.invoiceNumber;

const isPaymentStatusOk = R.flip (R.includes) (['ACCEPTED', 'SCHEDULED']);

/**
 * Applies payment elibility rules for a credit invoice.
 *
 * Checks if a paid credit invoice is re-usable based on payment
 * date expired.
 *
 * @param {String} timeZoneId
 * @returns true when payment date has expired
 */
export const isPaymentCreditExpired = timeZoneId => R.ifElse
  ( R.allPass
        ( [ isCreditInvoice
          , R.pathSatisfies(isPaymentStatusOk, ['paymentData', 'paymentStatus'])
          , R.hasPath(['paymentData', 'paymentDate'])
          ]
        )
  , R.compose
      ( R.lt(5) // read as 5 < n
    //   , R.tap(n => console.log(`${n} days old`))
      , paymentDate => momentTz(paymentDate).tz(timeZoneId) // Convert UTC to merchant timeZone
                            .isSame(momentTz().tz(timeZoneId).startOf('day'),'day') // Convert local time to merchant timeZone
                            ? 0
                            : momentTz().tz(timeZoneId).startOf('day') // Convert local time to merchant timeZone
                                        .diff(momentTz(paymentDate).tz(timeZoneId).startOf('day'), 'days') // Convert UTC to merchant timeZone
    //   , R.tap(d => console.log ('paymentDate', d))
      , R.path(['paymentData', 'paymentDate'])
      )
  , R.always(true)
  );

/**
 * Checks if a plan account invoice is for a plan payment.
 *
 * @param {Object} invoice invoice document
 * @return true if invoice is for a plan payment
 */
const isPlanPayment = R.allPass
    ( [ R.propSatisfies(R.complement(R.isNil), 'planNumber')
      , R.propSatisfies(R.complement(R.isNil), 'accountNumber')
      , R.propSatisfies(R.not, 'isPayable')
      ]
    );

/**
 * Checks if an invoice can be paid based on payableIr flag.
 *
 * @param {Object} invoice invoice document
 * @retrun true if payableIr check passes
 */
export const checkPayableIr = R.ifElse
    ( R.propEq('businessUnit', 'EBS')
    , R.ifElse
          ( R.allPass
                ( [ R.hasPath(['parent', 'payableIr']) // Have a plan?
                  , isPlanPayment // Plan payment
                  ]
                )
          , R.pathOr(true, ['parent', 'payableIr'])
          , R.propOr(true, 'payableIr') // if undefined assume true
          )
    , R.always(true)
    );

/**
 * Checks if an invoice can be paid based on the following rules.
 *
 *  * Invoice is not a credit note.
 *  * Invoice is in the corect status for payment.
 *  * If invoice is a credit invoice check for expiration.
 *  * Check `payableIr` rules.
 *  * Check `isPayable` rules.
 *
 * @param {Object} creditSupported merchant is configured for credit invoices
 * @param {String} timeZoneId merchant timezone id for converting to local user day time
 * @param {Object} invoice invoice invoice document
 *
 * @returns true if invoice is payable otherwise false
 */
const isPayableRamda = (creditSupported, timeZoneId, merchant) => R.allPass
( [ invoice => isFreightPaymentAllowed(merchant, invoice)
        , R.propSatisfies(type => type !== invoiceTypes.CREDIT_NOTE, 'invoiceType')
        , R.propSatisfies(status => status !== invoiceStatuses.CLOSED, 'invoiceStatus')
        , invoice => creditSupported ? isPaymentCreditExpired (timeZoneId) (invoice)
        : !isCreditInvoice (invoice)
        , checkPayableIr
        , R.anyPass([isPlanPayment, R.propOr(false, 'isPayable')])
    ]
);

export const isPayable = (creditSupported, timeZoneId, merchant)=>(invoice)=>{
    return (
        isPayableRamda(creditSupported, timeZoneId, merchant)(invoice)
        && !invoice.paymentData?.requiresUpdateForPayment
    )
}

export const isCreditInvoice = R.propSatisfies(R.flip(R.lte) (0), 'outstandingAmount');
export const isOverpayment = invoice => (invoice.outstandingAmount < 0) && (invoice.invoiceAmount > 0)
export const isCredit = invoice => (invoice.outstandingAmount < 0) && (invoice.invoiceAmount < 0)
export const isFreightPaymentAllowed = (merchant, invoice) => !(invoice.businessUnit === 'FRT' && !isFreightActive(merchant))

export const getAccountNumber = R.ifElse
    ( isPlanInvoice
    , R.prop('planNumber')
    , R.prop('accountNumber')
    );

/**
 * Try to match server payment status to translation label. If
 * this fails return the status string.
 *
 * @param {string} status server side payment status
 * @return {object} label label for i18n translation
 * @return {string} label server side label when no mapping is found
 */
 const paymentStatusToLabel = R.cond
 ( [ [ R.equals('ACCEPTED'), R.always({id: 'payment-history.search-field.status.option.ACCEPTED'}) ]
   , [ R.equals('AUTH'), R.always({id: 'payment-history.search-field.status.option.AUTH'}) ]
   , [ R.equals('CANCELED'), R.always({id: 'payment-history.search-field.status.option.CANCELED'}) ]
   , [ R.equals('DECLINED'), R.always({id: 'payment-history.search-field.status.option.DECLINED' }) ]
   , [ R.equals('FAILED'), R.always({id: 'payment-history.search-field.status.option.FAILED'}) ]
   , [ R.equals('PROCESSING'), R.always({id: 'payment-history.search-field.status.option.PROCESSING'}) ]
   , [ R.equals('QUEUED'), R.always({id: 'payment-history.search-field.status.option.QUEUED'}) ]
   , [ R.equals('RETURNED'), R.always({id: 'payment-history.search-field.status.option.RETURNED'}) ]
   , [ R.equals('SCHEDULED'), R.always({id: 'payment-history.search-field.status.option.SCHEDULED'}) ]
   , [ R.equals('SUBMITTED'), R.always({id: 'payment-history.search-field.status.option.SUBMITTED'}) ]
   , [ R.equals('VOID'), R.always({id: 'payment-history.search-field.status.option.VOID'}) ]
   , [ R.equals('REFUNDED'), R.always({id: 'payment-history.search-field.status.option.REFUNDED'}) ]
   , [ R.equals('ERROR'), R.always({id: 'payment-history.search-field.status.option.ERROR'}) ]
   , [ R.equals('CARD_PRESENT_STAGED'), R.always({id: 'payment-history.search-field.status.option.CARD_PRESENT_STAGED'}) ]
   , [ R.T, R.identity ] // Functions normally must return the same type however this is javascript and we really don't have ADTs
   ]
 );

export const displayPaymentStatus = intl => R.compose
 ( R.when (R.is(Object), o => intl.formatMessage(o))
 , paymentStatusToLabel
 );

export const getMultiSelectList = (options, selected, type, props, useMetadata = true) => {
    let optionList = [];
    const { intl, invoiceMetadata } = props;

    let selectionArr = [];
    if (Array.isArray(selected)) {
        selectionArr = selected;
    } else if (selected) {
        selectionArr = selected.split(',');
    }

    Object.keys(options).forEach(function(key) {
        optionList.push( {
            checked: selectionArr.includes(key) || selectionArr.length === 0,
            disabled: false,
            value: key,
            text: useMetadata ? intl.formatMessage({ id: invoiceMetadata[type][key].msgId }) : key,
            label: useMetadata ? intl.formatMessage({ id: invoiceMetadata[type][key].msgId }) : key,
            msgId : useMetadata ? invoiceMetadata[type][key].msgId : key
        });
    });

    return optionList;
};

export const buildFilters = (props, forPlan) => {
    const filterType = lodashGet(props, 'match.params.filterType');
    let filters = {};
    if (filterType) {
        if (filterType === 'pastdue') {
            filters = { invoiceStatus: [invoiceStatuses.PAST_DUE] };
        }
    }
    // Apply for DueSoon
    const invoiceDueOption = lodashGet(props, 'location.state.invoiceDueOption');
    if(invoiceDueOption){
        filters = { invoiceStatus: [invoiceStatuses.OPEN], invoiceTypeNotIn:[invoiceTypes.CREDIT_NOTE, invoiceTypes.EXPORT_PLAN_CREDIT_NOTE],
        debitOnly: true, paymentusPaymentDataStatusNotIn: ['ACCEPTED'],  ...(!forPlan?{isPayable:true, payableIr:true}:{}) };
    }

    // filters received in query params - case for email notifications
    const { type, invoiceNumbers, planInvoiceNumbers, accountNumber, dueDate, planNumber } = props;
    if (type) {        
        if (accountNumber && planNumber) { //plan_account
            filters = {
                planNumber,
            }
        } else {//account or plan
            filters = {
                ...(planNumber ? { planNumber } : {}),
                ...(accountNumber ? { accountNumber } : {}),
                ...(dueDate ? { dueDate: { dateBegin: moment.utc(dueDate.fromDate).format('YYYY-MM-DD'), dateEnd: moment.utc(dueDate.toDate).add(1, 'days').format('YYYY-MM-DD') } } : {})
            }
        }

        filters = {
            ...filters,
            invoiceStatus: type === "INVOICE_READY" || type === "PAYMENT_REMINDER" ? [invoiceStatuses.OPEN] : [invoiceStatuses.PAST_DUE],
            ...(planInvoiceNumbers?.length ? { planInvoiceNumber: planInvoiceNumbers } : {}),
            ...(invoiceNumbers?.length > 0 ? { invoiceNumber: invoiceNumbers } : {})
        }
    }
    
    return filters;
};
export const buildCalendarOption = (props) => {
    const option = lodashGet(props, 'location.state.invoiceDueOption', {});
    let calendarOptions = {};
    if (option) {
          switch (option) {
            case "TODAY":
                calendarOptions = { calendarOptionType: "TODAY", dateType: "2" };
              break;
            case "NEXT_7_DAYS":
                calendarOptions = { calendarOptionType: "NEXT_7_DAYS", dateType: "2" };
              break;
            case "NEXT_30_DAYS":
                calendarOptions = { calendarOptionType: "NEXT_30_DAYS", dateType: "2" };
              break;
          }
    }
    return calendarOptions;
};

export function getDownloadEligibility(businessUnit, recordType, isLocalCsv=false) {
    let eligibility = [];
    if (businessUnit === 'EBS') {
        eligibility = ['pdf', 'csv', 'xml'];
        if (!['PLAN', 'PLAN_ACCOUNT'].includes(recordType)) {
            eligibility.push(isLocalCsv ? 'lcsv':'csv_lite');
        }
    } else if (businessUnit === 'FRT') {
        eligibility = ['pdf'];
    } else if (businessUnit === 'SCS') {
        eligibility = ['pdf'];
        if (recordType !== 'STATEMENT') {
            eligibility.push('csv');
        }
    }
    return eligibility;
}

export const getInvoiceDownloadRecordType = _.cond([
    [({ recordType }) => ['PLAN', 'PLAN_ACCOUNT', 'ACCOUNT'].includes(recordType), ({ recordType }) => recordType],
    [_.stubTrue, _.constant('ACCOUNT')]
]);

export function getDownloadRequestObject(invoice, businessUnit, isPlanInvoice, intl) {
    const languageCode = intl.locale.split('-')[0].toUpperCase();
    let searchCriteria = {};

    if (businessUnit === BUSINESS_UNIT.FRT || businessUnit === BUSINESS_UNIT.SCS) {
        if (isPlanInvoice) {
            searchCriteria = {
                accountNumber: invoice.accountNumber,
                planNumber: invoice.planNumber,
                invoiceNumber: invoice.planInvoiceNumber,
                invoiceDate: moment(invoice.invoiceDate.split('T')[0]).format('DD/MM/YY'),
                invoiceAmount: invoice.invoiceAmount.toFixed(2),
                countryCode: invoice.countryCode,
                businessUnit,
                isPlanInvoice,
                ...(!lodashIsNil(invoice.versionNumber) ? { invoiceVersion: `${invoice.versionNumber}`.padStart(6, '0') } : {}),
                ...(businessUnit === BUSINESS_UNIT.SCS ? {
                    invoiceType: getInvoiceTypeForDownload(invoice)
                } : {}),
                recordType: getInvoiceDownloadRecordType(invoice)
            }
        } else {
            searchCriteria = {
                accountNumber: invoice.accountNumber,
                planNumber: invoice.planNumber,
                invoiceNumber: invoice.invoiceNumber,
                businessUnit,
                recordType: getInvoiceDownloadRecordType(invoice)
            };
            if (businessUnit === BUSINESS_UNIT.FRT) {
                searchCriteria = {
                    ...searchCriteria,
                    invoiceType: 'PRO'
                }
            } else {
                searchCriteria = {
                    ...searchCriteria,
                    ...(!lodashIsNil(invoice.versionNumber) ? { invoiceVersion: `${invoice.versionNumber}`.padStart(6, '0') } : {}),
                    languageCode,
                    invoiceAmount: invoice.invoiceAmount.toFixed(2),
                    countryCode: invoice.countryCode,
                    invoiceDate: moment(invoice.invoiceDate.split('T')[0]).format('DD/MM/YY'),
                    invoiceType: getInvoiceTypeForDownload(invoice),
                    recordType: getInvoiceDownloadRecordType(invoice)
                }
            }
        }
    } else {
        searchCriteria = {
            countryCode: invoice.countryCode,
            languageCode,
            invoiceDate: moment(invoice.invoiceDate.split('T')[0]).format('DD/MM/YY'),
            invoiceAmount: invoice.invoiceAmount.toFixed(2),
            businessUnit,
            recordType: getInvoiceDownloadRecordType(invoice)
        };
        if (isPlanInvoice) {
            searchCriteria = {
                ...searchCriteria,
                // accountNumber: invoice.planNumber,
                accountNumber: invoice.accountNumber,
                planNumber: invoice.planNumber,
                uniqueId: invoice.planInvoiceUniqueId,
                invoiceType: getInvoiceTypeForDownload(invoice),
                isPlanInvoice
            };
        } else {
            searchCriteria = {
                ...searchCriteria,
                accountNumber: invoice.accountNumber,
                planNumber: invoice.planNumber,
                invoiceType: getInvoiceTypeForDownload(invoice),
                invoiceNumber: invoice.invoiceNumber
            };
        }

    }

    return searchCriteria;
}

const isDomesticExport = ({ businessUnit, countryCode, invoiceType }) =>
    businessUnit === BUSINESS_UNIT.EBS
    && ['US','CA'].includes(countryCode)
    && [
        invoiceTypes.EXPORT,
        invoiceTypes.DOMESTIC_EXPORT_CPP_INVOICE,
        invoiceTypes.DOMESTIC_EXPORT_CPP_PLAN,
        invoiceTypes.CBS_PLAN,
        invoiceTypes.CBS_INVOICE
    ].includes(invoiceType);

const isDomesticImport = ({ businessUnit, countryCode, invoiceType }) =>
    businessUnit === BUSINESS_UNIT.EBS
    && ['US','CA'].includes(countryCode)
    && [
        invoiceTypes.IMPORT,
        invoiceTypes.IMPORT_CPP_INVOICE,
        invoiceTypes.IMPORT_CPP_PLAN
    ].includes(invoiceType);

const isScsAir = ({ businessUnit, invoiceType }) =>
    businessUnit === BUSINESS_UNIT.SCS
    && invoiceType === invoiceTypes.SCS_AIR;

const isScsAirLpf = ({ businessUnit, invoiceType }) =>
    businessUnit === BUSINESS_UNIT.SCS
    && invoiceType === invoiceTypes.SCS_AIR_LPF_INVOICE;

const isScsBrokerage = ({ businessUnit, invoiceType }) =>
    businessUnit === BUSINESS_UNIT.SCS
    && invoiceType === invoiceTypes.SCS_BROKERAGE;

const isScsBrokerageLpf = ({ businessUnit, invoiceType }) =>
    businessUnit === BUSINESS_UNIT.SCS
    && invoiceType === invoiceTypes.SCS_BROKERAGE_LPF_INVOICE;

const isScsOcean = ({ businessUnit, invoiceType }) =>
    businessUnit === BUSINESS_UNIT.SCS
    && invoiceType === invoiceTypes.SCS_OCEAN;

const isScsOceanLpf = ({ businessUnit, invoiceType }) =>
    businessUnit === BUSINESS_UNIT.SCS
    && invoiceType === invoiceTypes.SCS_OCEAN_LPF_INVOICE;

const isMailInnovation = ({ businessUnit, invoiceType }) =>
    businessUnit === BUSINESS_UNIT.SCS
    && invoiceType === invoiceTypes.SCS_MAIL_INNOVATIONS;

    const isMailInnovationLpf = ({ businessUnit, invoiceType }) =>
    businessUnit === BUSINESS_UNIT.SCS
    && invoiceType === invoiceTypes.SCS_MAIL_INNOVATIONS_LPF_INVOICE;

const getInvoiceTypeForDownload = _.cond([
    [isDomesticImport,      _.constant('IMPORT')],
    [isDomesticExport,      _.constant('EXPORT')],
    [isScsAir,              _.constant('Air')],
    [isScsAirLpf,           _.constant('Air')],
    [isScsOcean,            _.constant('Ocean')],
    [isScsOceanLpf,         _.constant('Ocean')],
    [isScsBrokerage,        _.constant('Brokerage')],
    [isScsBrokerageLpf,     _.constant('Brokerage')],
    [isMailInnovation,      _.constant('Mail Innovations')],
    [isMailInnovationLpf,   _.constant('Mail Innovations')],
    [_.stubTrue,            ({ invoiceType }) => _.constant(invoiceType)],
]);

export const isFullInvoicingEnabled = (merchant) => lodashGet(merchant, 'fullInvoicingEnabled', false);
export const isDisputesEnabled = (merchant, BU, disableFRTDisputes) => {
    if(BU === "FRT"){
        return !disableFRTDisputes && lodashGet(merchant, 'disputesEnabled', false)
    }
    return lodashGet(merchant, 'disputesEnabled', false)
};
export const isEmptyTemplatingString = (value, template, displayEmptyValue) => {
    if(lodashIsEmpty(lodashTrim(value))) return displayEmptyValue ? "" : undefined;
    const verifyString = (isEmpty, string)=> isEmpty(string) ? undefined : string;
    const checkEmptyString = R.curry(verifyString);
    return template ? checkEmptyString(lodashIsEmpty)($.trim($($.parseHTML(value)).text())) : value;
}

export const isCreditInvoiceSupported = R.allPass
  ( [ R.pathOr(false, ['merchant', 'clientConfiguration', 'CREDIT_INVOICES_SUPPORTED'])
    , R.pathOr(false, ['businessUnit', 'supportsCreditInvoices'])
    ]
  );

export const getMerchantTimeZoneId = R.path(['merchant', 'timeZoneId']);

export function filterInvoices(invoices, filters) {
    const filterKeys = Object.keys(filters);
    return invoices.filter(inv => {
        return filterKeys.every(key => {
            let value = filters[key];
            if (Array.isArray(value)) {
                return value.find(f => f === inv[key])
            } else if (typeof value === "object" && key === "dueDate") {
                return (moment(moment.utc(inv[key]).format("YYYY-MM-DD")).isBetween(moment(value.dateBegin), moment(value.dateEnd), null, []));
            } else {
                return value === inv[key]
            }

        });
    });
}

export function areInvoiceDocumentsDownloadable(merchant, {invoiceType}) {
    return !(noDocumentDownloadInvoiceTypes(merchant).includes(invoiceType))
}

// removes filter data that should not be saved in the user preferences
export const sanitizeInvoiceFilter = (filter, isDrillMode) => ({
    ...filter,
    filterType: '',
    invoiceFilters: '',
    debitOnly:'',
    isPayable:'',
    payableIr:'',
    invoiceTypeNotIn:'',
    paymentusPaymentDataStatusNotIn:'',
    invoiceNumber:'',
    ...(!isDrillMode ? {planInvoiceNumber: '', planNumber:''} : {})
})

// removes filter data that should not be saved in the user preferences
export const sanitizePlanInvoiceFilter = (filter) => ({
    ...filter,
    filterType: '',
    invoiceFilters: '',
    debitOnly:'',
    isPayable:'',
    payableIr:'',
    invoiceTypeNotIn:'',
    paymentusPaymentDataStatusNotIn:''
})


export const generateStatementList = (data, intl, invoiceMetadata, isPlanInvoice, businessUnit, isXmlEnrolled, downloadEligibility = []) => {
    const { invoiceDownloads } = invoiceMetadata;
    const documentList = [];

    if (!data || Object.keys(data).length === 0) return documentList;

    for (let [key, value] of Object.entries(invoiceDownloads)) {
        if (!downloadEligibility.includes(key) || (key === 'xml' && !isXmlEnrolled)) continue;

        let statement = data.invoiceNumber;
        let name = data.invoiceNumber;

        if (isPlanInvoice) {
            if(businessUnit === "EBS"){
                statement =  stripAccountNumber(data.planNumber, businessUnit);
                name = `${stripAccountNumber(data.planNumber, businessUnit)} (${formatInvoiceDate(intl, data.invoiceDate)})`;
            }else{
                statement =  data.planInvoiceNumber;
                name = `${data.planInvoiceNumber} (${formatInvoiceDate(intl, data.invoiceDate)})`;
            }
        }

        documentList.push({
            statement,
            name,
            description: '',
            type: key === 'lcsv' ? 'CSV_LITE': key.toUpperCase(),
            url: value,
            invoice: data
        });
    }
    return documentList;
};

//downloads an invoice as a specific type (csv, csv_lite, pdf, xml)
export const handleInvoiceDownload = async (url, type, invoice, intl, isPlanInvoice, businessUnit, userLocale) => {
    const {invoiceType, businessUnit: invoiceBusinessUnit, merchantId, accountNumber, planInvoiceNumber} = invoice;

    let scsStatement;
    if (businessUnit === 'SCS') {
        // for scs pdf downloads, we need to fetch the statement and modify some of the fields to match the statement
        if (type.toLowerCase() === 'pdf' && [INVOICE_TYPES.SCS_AIR, INVOICE_TYPES.SCS_BROKERAGE, INVOICE_TYPES.SCS_OCEAN].includes(invoiceType)) {
            const response = await getPlanInvoiceListPromise({
                merchantId: merchantId,
                businessUnit: invoiceBusinessUnit,
                accountNumber: accountNumber,
                planInvoiceNumber: planInvoiceNumber
            })
            if (Array.isArray(response)) {
                scsStatement = response[0];
            }
        }
    }

    const searchCriteria = getDownloadRequestObject({
        ...invoice,
        ...(scsStatement ? {
            invoiceDate: scsStatement.invoiceDate,
            invoiceNumber: scsStatement.planInvoiceNumber,
            invoiceAmount: scsStatement.invoiceAmount,
        } : {})
    }, businessUnit, isPlanInvoice, intl);

    //returns a promise that can be .then'd or awaited and such
    return getInvoiceDownloadPromise(url, {locale: userLocale, invoices: [searchCriteria]});
};

export const getInvoiceDownloadErrorMsg = (response, invoiceMetadata) => {
    let errorMsg = <FormattedMessage id={'page.unexpectedError'}/>
        //<FormattedHTMLMessage tag={'p'} id={'invoice.downloadInvoices.unexpected-body'}/>
    let errorTitle = 'invoice.downloadInvoices.downloadError-title'
    const errors = lodashGet(response.parsedBody, 'error.errorBody.response.errors', [])
        .filter(({ code }) => ["110400"].includes(code))
        .map(({ code }) => <FormattedMessage id={`invoice.download.${code}`}/>);
    if(response.status === 404){
        errorMsg = <FormattedMessage id={'invoice.download.not.found'}/>
    }
    else if (response.status === 400 && errors.length > 0){
        errorMsg = errors
    }
    else if (response.status === 403 && errors?.parsedBody?.msg === INVOICE_PENDING_DOWNLOAD_MSG) {
        errorTitle = 'invoice.downloadInvoices.unable-title'
        errorMsg = <FormattedMessage
            id={'invoice.downloadInvoices.pending-unable-body'}
            values={{ expiryTime: lodashGet(invoiceMetadata, 'pendingDownloadRules.downloadExpiry', 24) }}
        />
    }
    else if (response.status === 413) {
        errorTitle = 'invoice.downloadInvoices.unable-title'
        errorMsg = <FormattedMessage id={'invoice.downloadInvoices.unable-body'} />
    }
    return {errorMsg, errorTitle}
}

export const isInvoiceXmlDownloadEligible = async (invoice, countryCode, businessUnit, adUserId, isPlanInvoice) => {
    //if xmlIr is undefined, need to check if the account is enrolled
    if(invoice.xmlIr === undefined && !adUserId){
        const xmlRequest = {
            accountType: isPlanInvoice ? 'Plan' : 'Account',
            accountNumber: invoice.accountNumber,
            countryCode,
            businessUnit
        };
        try{
            const response = await accountApi.getXmlEnrollment(xmlRequest)
            return response?.parsedBody?.mediaFormatStatus !== 'N'
        } catch(e){
            return false
        }
    }
    else return (invoice.xmlIr === "1")
}
