import React, { Component } from 'react';
import classNames from "classnames";
import { injectIntl, FormattedMessage } from 'react-intl';
import MaskedNumberField from '../MaskedNumberField';
import { MDBPopoverWrapper } from "../MDBFix/MDBPopoverWrapper";

class CardNumberField extends Component {
    render() {
        const { formClassName, className, rightSideSpanClass, acceptedCards,acceptedCardLabels, ...rest } = this.props;
        return (
            <div className={classNames("md-form custom-input-form",formClassName)}>
                <MaskedNumberField
                    format="#### #### #### #### ###"
                    className={classNames("cardNumber-input",className)}
                    noContainer
                    unformat={(val)=>val.replace(/\s/g,"")}
                    labelClass={"card-display-popup"}
                    extraLabelInfo={acceptedCards?.length > 5 && 
                            <MDBPopoverWrapper
                                buttonLabel={<FormattedMessage id="tooltip.popover.cards" />}
                                popoverType = "INFO"
                                content={
                                    <div>
                                    <ul>
                                        {acceptedCardLabels.map((item)=>(
                                            <li><FormattedMessage id={`${item}`}/></li> 
                                        ))
                                        }
                                    </ul></div>}
                            />
                    }
                    {...rest}
                />
                {rightSideSpanClass && <span className={rightSideSpanClass}></span>}
                {acceptedCards && <DisplayAcceptedCards acceptedCards={acceptedCards} />}
            </div>
        );
    }
}

const DisplayAcceptedCards = ({acceptedCards}) => {
    return acceptedCards.filter((data, idx) => idx < 5).map((item) => (
        <span key={item} className={classNames("pm-accepted-cards pm-icon", item)}/>
    ));
};

export default injectIntl(CardNumberField);