import React,  { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol  } from 'mdbreact';
import ReactPortalModal from "../../../ReactPortalModal";
import UPSInvoiceSearchCriteria from "../../UPSInvoiceSearchCriteria";
import * as invoiceActions from "../../../../actions/invoice-action";
import * as identityActions from "../../../../actions/identity-action";
import {createLookupTable} from "../../../../utils/utils";
import {sanitizeInvoiceFilter} from "../../../../utils/invoice-utils";

class InvoiceSearchCriteriaModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...(props.invoiceFilters ? props.invoiceFilters : {})
        };
    }

    checkMultiSelectAll = (value, hasAll) => {
        return hasAll ? undefined : value //if we have selected every option, we pass undefined, it's the same as "no filter"
    }

    handleInvoiceSearch = () => {
        const { identityActions, runFilter, isDrillMode } = this.props;
        const { toggleModal, invoiceFilters, savedPreferenceType } = this.props;
        const { accountNumber, invoiceStatus, invoiceType, paymentStatus, saveSearchCriteria, freightTerms } = this.state;
        const { invoiceStatusSelectAll, invoiceTypeSelectAll, paymentStatusSelectAll, freightTermsSelectAll, accountNumberSelectAll } = this.state;
        
        const newFilter = sanitizeInvoiceFilter({
            ...invoiceFilters,
            invoiceStatus: this.checkMultiSelectAll(invoiceStatus, invoiceStatusSelectAll),
            invoiceType: this.checkMultiSelectAll(invoiceType, invoiceTypeSelectAll),
            paymentStatus: this.checkMultiSelectAll(paymentStatus, paymentStatusSelectAll),
            freightTerms: this.checkMultiSelectAll(freightTerms, freightTermsSelectAll),
            accountNumber: this.checkMultiSelectAll(accountNumber, accountNumberSelectAll)
        }, isDrillMode);

        if (saveSearchCriteria) identityActions.saveIdentityPreferences({[savedPreferenceType]: newFilter});

        runFilter(newFilter);
        toggleModal('filter');
    };

    onUpdateInvoiceFilters = (key, value, allSelection) => {
        let allSelected = false;
        if(allSelection){
            const lookup = createLookupTable(value)
            allSelected = allSelection.reduce((acc, option)=>{
                acc &= lookup[option.value]
                return acc
            }, true)
        }
        this.setState({ 
            [key]: value,
            [`${key}SelectAll`]: allSelected
        }); 
    }

    clearFilters = () => {
        const { toggleModal, clearFilter } = this.props;
        clearFilter()
        toggleModal('filter');
    }

    render() {
        const { isOpen, toggleModal, backdrop, isDrillMode, invoice, intl } = this.props;
        const modalHeading = "invoice.search-title";

        if (!isOpen) return null;

        return (
            <ReactPortalModal isOpen={isOpen} an_label="invoice-search-criteria">
                <MDBModal id={'invoice-search-modal'} isOpen={isOpen} toggle={() => toggleModal('filter')} size="lg" backdrop={backdrop} centered disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
                    <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"}, { name: intl.formatMessage({ id: modalHeading }) })} toggle={() => toggleModal('filter')} ><FormattedMessage id={modalHeading}/></MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol size={"12"}>
                                <UPSInvoiceSearchCriteria 
                                    modalMode 
                                    key={'invoice-search-criteria'} 
                                    onUpdateInvoiceFilters={this.onUpdateInvoiceFilters} 
                                    invoice={invoice}
                                    invoiceFilters={this.state} 
                                    isDrillMode={isDrillMode}
                                    saveSearchCriteria={this.state.saveSearchCriteria}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn id={'invoice-search-btn-search'} color="primary" onClick={this.handleInvoiceSearch}><FormattedMessage id={"search.label"}/></MDBBtn>
                        <MDBBtn id={'invoice-search-btn-cancel'} color="cancel" onClick={this.clearFilters}><FormattedMessage id={"clear.label"}/></MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </ReactPortalModal>
        )
    }

}

function mapStateToProps(state, ownProps) {
    return {
        layout: state.layout,
        preferences: state.identity.preferences,
        businessUnit: state.auth.user.selectedPaymentType,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        invoiceActions: bindActionCreators(invoiceActions, dispatch),
        identityActions: bindActionCreators(identityActions, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(InvoiceSearchCriteriaModal)));
