import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import CheckboxIntl from '../../CheckboxIntl';
import { InputRestrictors, StandardValidations as stdv } from '../../../utils/validation/standard-validations';
import { MDBBtn, MDBCol, MDBRow, MDBContainer, MDBCard, MDBCardBody, MDBSwitch } from 'mdbreact';
import MaskedNumberField from '../../MaskedNumberField';
import InputFieldIntl from '../../InputFieldIntl';
import attachValidator from "../../../utils/validation/attach-validator.js";
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import * as identityActions from "../../../actions/identity-action";
import * as validationActions from "../../../actions/validation-action";
import { bindActionCreators } from 'redux';
import { set as lodashSet, get as lodashGet } from 'lodash';
import produce from "immer";
import CommonModalData from '../Modal/CommonModalData';
import { formatBool } from "../../../utils/utils.js"
import SaveModal from '../Modal/SaveModal';
import PreferencesPrompt from './PreferencesPrompt';
import NotificationModal, { BUTTON_TYPES } from '../Modal/NotificationModal';

class SecurePDFPreferences extends Component {

    constructor(props) {
        super(props);
        this.state = this.getIntialState();
        attachValidator.call(this);
    }

    getIntialState = () => {
        const userPreferences = this.getUserPreferences();
        return {
            pdf: userPreferences.invoiceSettings.pdf,
            confirmPasswordCheck: false,
            openModal: false,
            modalComp: '',
            saveButtonModalComp: '',
            saveButtonOpenModal: false,
            saveModalData: <CommonModalData />,
            saveModalHeading:'',
            modalHeadingCssClass:'',
            isPageDirty: false,
            showErrorMessage: false,
            showPDFPassword: false,
            showPDFConfPassword:false
        }
    }

    savePreferences = (pdfPref, commonSaveModalData) => {
        let result = this.validator.validateAll();
        if (result.messages.length > 0) return false;

        const { identityActions, preferences } = this.props;
        const { pdf } = this.state;

        if(pdf?.secure){
            let notifications = JSON.parse(JSON.stringify(preferences.notifications));
            notifications.emailSettings.triggers.invoiceReadyForView = true;
            pdfPref["notifications"] = notifications;
        }

        this.setState({
            saveModalData: commonSaveModalData
        })

        if (Object.keys(pdfPref).length) {
            identityActions.saveIdentityPreferences(pdfPref, (result) => {
                if(result.status === true){
                    this.setState({
                        saveButtonOpenModal: true,
                        saveButtonModalComp: <SaveModal />
                    });
                    this.hideConfirmPassword();
                }
                else{
                    this.setState({
                        showErrorMessage: true,
                        errorCode : result.errorCode ?? 'internalError'
                    });
                }
            });
        }
    }

    closeModal = () => {
        this.setState({
            openModal: false
        });
    }

    saveButtonCloseModal = () => {
        this.setState({
            saveButtonOpenModal: false
        });
    }

    showConfirmPassword = () => {
        this.setState(
            { confirmPasswordCheck: true }
        );
    }

    hideConfirmPassword = () => {
        this.setState(
            { confirmPasswordCheck: false,
                showPDFPassword: false,
                showPDFConfPassword:false 
            }
        );
    }

    handlePreferenceChange = () => {
        let { pdf, isPageDirty } = this.state;
        const savedSettings = this.props.preferences?.invoiceSettings?.pdf;
        let currentSettings = JSON.parse(JSON.stringify(pdf));
        delete currentSettings["reenterPassword"];

        if(JSON.stringify(savedSettings) !== JSON.stringify(currentSettings)){
            isPageDirty = true;
        }
        else{
            isPageDirty = false;
        }

        this.setState({
            isPageDirty:  isPageDirty
        });
    }

    handleInput = e => {
        const inputName = e.target.name;
        const inputValue = e.target.value;

        this.setState(
            produce(draft => {
                lodashSet(draft, inputName, inputValue);
            }), () => {
                this.handlePreferenceChange();
            }
        )
    };

    handlePasswordIconClick = (propName) =>{
        const value = this.state[propName];

        this.setState(
            { [propName]: !value }
        );
    }

    handleCheckBox = (e, name) => {
        const inputName = e.target.name ? e.target.name  : name;
        const inputValue = e.target.checked;

        this.setState(
            produce(draft => {
                lodashSet(draft, inputName, inputValue);
            }), () => {
                this.handlePreferenceChange();
            }
        );
    }

    getSecurePdfPreferences() {
        let pdfPref = { invoiceSettings: { pdf: { secure: false } } };
        const { preferences } = this.props;
        const triggerKey = Object.keys(preferences.invoiceSettings.pdf);
        triggerKey.forEach((key) => {
            pdfPref.invoiceSettings.pdf[key] = preferences.invoiceSettings.pdf[key];
        });

        if (triggerKey) {
            pdfPref.invoiceSettings.pdf.reenterPassword = preferences.invoiceSettings.pdf.password;
        }

        return pdfPref;
    }

    getUserPreferences() {
        const { preferences } = this.props;

        let securePdfPreferences = {
            invoiceSettings: { pdf: { secure: false } },
        };

        if (preferences) {
            if (preferences.invoiceSettings && preferences.invoiceSettings.pdf) {
                securePdfPreferences = { ...securePdfPreferences, ...(this.getSecurePdfPreferences()) };
            }
        }
        return securePdfPreferences;
    }

    updateSaveMessages(pdfPref) {
        const { pdf } = this.state;
        const { preferences } = this.props;

            let saveModalHeading = "ups.preferences.btn.savepassword";
            let saveModalHeadingCssClass = "modal-title-center";

            let pdfSaveMessageType;
            if (pdf?.secure === false && preferences?.invoiceSettings?.pdf?.secure === true) {
                pdfSaveMessageType = "unenrolled";
                saveModalHeading = "";
                saveModalHeadingCssClass="";
            }

            this.setState({
                saveModalHeading: saveModalHeading,
                modalHeadingCssClass:saveModalHeadingCssClass
            })

            this.savePreferences(pdfPref, <CommonModalData dataId={'securepdf'} messageType={pdfSaveMessageType} />);
    }

    prepareSecurePdfPreferences = () => {
        let pdfPref = {};
        
        const { preferences, vState } = this.props;
        const { pdf } = this.state;
        let prefinvoiceSettings = JSON.parse(JSON.stringify(pdf));

        if (pdf.password != pdf.reenterPassword) {
            if (pdf.secure == false) {
                if (preferences.invoiceSettings && preferences.invoiceSettings.pdf) {
                    prefinvoiceSettings['password'] = preferences.invoiceSettings.pdf.password;
                }
                else {
                    delete prefinvoiceSettings['password'];
                }
            }
        }

        delete prefinvoiceSettings['reenterPassword'];
        pdfPref = {
            invoiceSettings: {
                pdf: prefinvoiceSettings
            }
        };        

        this.updateSaveMessages(pdfPref);
    }

    handleSavePDF = () => {
        let result = this.validator.validateAll();
        if (result.messages.length == 0){
            this.setState({isPageDirty: false});
        }
        this.prepareSecurePdfPreferences();
    }

    render() {
        const { auth, disableSecurePdf, preferences, intl } = this.props;
        const { pdf, confirmPasswordCheck, saveButtonOpenModal, saveButtonModalComp, saveModalData, saveModalHeading,modalHeadingCssClass, isPageDirty, showErrorMessage, errorCode, showPDFPassword, showPDFConfPassword } = this.state;
        const isPdfEnabledInUserPref = preferences?.invoiceSettings?.pdf?.secure || false;
        const hidePdfDataAndBtn = !isPdfEnabledInUserPref && !pdf.secure;
        return (
            <MDBContainer role="main" fluid>
                <MDBRow>
                    <MDBCol>
                        <MDBCard>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol size={"12"}>
                                        <nav className="ups-breadcrumb ups-wrap hpps-basic" role={"navigation"}  aria-label={intl.formatMessage({id: "ups.you-are-here.label"})}>
                                            <ol className="ups-wrap_inner">
                                            <li className="ups-breadcrumb-item">
                                                    <Link className="ups-link" to="/home"><FormattedMessage id={'ups.title.dashboard'} /></Link>
                                                    <span aria-hidden="true"> &gt; </span></li>
                                                <li className="ups-breadcrumb-item">
                                                    <Link className="ups-link" to="/ups/billing/profile"><FormattedMessage id={'ups.title.profile'} /></Link>
                                                    <span aria-hidden="true"> &gt; </span>
                                                </li>
                                                <li className="ups-breadcrumb-item">
                                                    <FormattedMessage id="ups.preferences.pdfinvoice.title" />
                                                </li>
                                            </ol>
                                        </nav>
                                    </MDBCol>
                                </MDBRow>
                                <h1 className={"has-breadcrumb"}>
                                    <FormattedMessage id="ups.preferences.pdfinvoice.title" />
                                </h1>

                                <React.Fragment>
                                    <div className="ups-breadcrumb preferences">

                                        <MDBRow> 
                                            <MDBCol size="12" className="mt-3">
                                                <FormattedMessage id="ups.preferences.pdf-invoice.note1" />
                                            </MDBCol>

                                            <MDBCol size="12" className="mt-3">
                                            <span className="font-weight"><FormattedMessage id="ups.preferences.pdf-invoice.note2" /></span>
                                                <FormattedMessage id="ups.preferences.pdf-invoice.note3" />
                                            </MDBCol>

                                            <MDBCol size="12" className="mt-3">
                                                <MDBSwitch
                                                    name={"pdf.secure"}
                                                    labelRight=''
                                                    labelLeft={<span><FormattedMessage id="ups.preferences.pdf-invoice.pref.option" /></span>}
                                                    checked={pdf.secure}
                                                    onChange={(e) => this.handleCheckBox(e, "pdf.secure")} 
                                                />
                                            </MDBCol>                                          
                                            <div className={!pdf?.secure && isPdfEnabledInUserPref ? "disable-section col-12 mt-2 pl-0" : "col-12 mt-2 pl-0"}>
                                                {!hidePdfDataAndBtn ?
                                                    <React.Fragment>
                                                        

                                                        <MDBCol size="12" className="mt-3">
                                                            <><FormattedMessage id={"businessUnit.hint.line5"} />:
                                                                <ul>
                                                                    <li><FormattedMessage id={"businessUnit.hint.line6"} /></li>
                                                                    <li><FormattedMessage id={"businessUnit.hint.line7"} /></li>
                                                                </ul>
                                                             </>
                                                            {/* <FormattedMessage id="ups.preferences.pdf-invoice.note3" /> */}
                                                        </MDBCol>

                                                        <MDBCol size="4" className="mt-4">
                                                            <div className="md-form">
                                                                <div className='popover-div'>
                                                                    <FormattedMessage id="ups.preferences.pdf-invoice.pdf-password" />&nbsp;&nbsp;                                                                   
                                                                </div>
                                                                <InputFieldIntl
                                                                    type={showPDFPassword?"text":"password"}
                                                                    restrict={stdv.alphanumeric}
                                                                    name={"pdf.password"}
                                                                    value={pdf.secure ? pdf.password : ""}
                                                                    hint={`ups.preferences.pdf-invoice.pdf-password`}
                                                                    onKeyUp={this.showConfirmPassword}
                                                                    onChange={this.handleInput}
                                                                    validations={pdf.secure ? [
                                                                        ['required', "field.required"],
                                                                        ["alphanumeric", "field.alphanumeric"],
                                                                        ['minmaxlength', "field.minmaxlength4", { min: 4, max: 6 }]
                                                                    ] : [] }
                                                                    aria-required="true"
                                                                    icon={showPDFPassword?"eye":"eye-slash"}
                                                                    iconClass={"input-clear-icon input-eye-icon-right"}
                                                                    onIconClick={() => this.handlePasswordIconClick("showPDFPassword")}
                                                                />
                                                            </div>
                                                        </MDBCol>

                                                        <MDBCol size="12" md="8" className="mt-4"></MDBCol>

                                                        {confirmPasswordCheck ? <MDBCol size="4" className="mt-2">
                                                            <div className="md-form">
                                                                <div className="popover-div">
                                                                    <FormattedMessage id="ups.preferences.pdf-invoice.pdf-reenter-password" />
                                                                </div>
                                                                <InputFieldIntl
                                                                    type={showPDFConfPassword?"text":"password"}
                                                                    restrict={stdv.alphanumeric}
                                                                    name={"pdf.reenterPassword"}
                                                                    value={pdf.secure ? pdf.reenterPassword : ""}
                                                                    hint={`ups.preferences.pdf-invoice.pdf-reenter-password`}
                                                                    onChange={this.handleInput}
                                                                    validations={pdf.secure ? [
                                                                        ['required', 'field.required'],
                                                                        ['alphanumeric', "field.alphanumeric"],
                                                                        ['match', "password.mismatch", {
                                                                            value1: pdf.password
                                                                        }
                                                                        ],
                                                                        ['minmaxlength', "field.minmaxlength4", { min: 4, max: 6 }]
                                                                    ] : []}
                                                                    aria-required="true"
                                                                    icon={showPDFConfPassword?"eye":"eye-slash"}
                                                                    iconClass={"input-clear-icon input-eye-icon-right"}
                                                                    onIconClick={() => this.handlePasswordIconClick("showPDFConfPassword")}
                                                                />
                                                            </div>
                                                        </MDBCol> : null}

                                                    </React.Fragment> : null}
                                            </div>
                                        </MDBRow>
                                    </div>
                                </React.Fragment>

                                {auth.user.adUserId || hidePdfDataAndBtn ? null :
                                    <MDBRow>
                                        <MDBCol size="12" className="mt-3 d-flex d-md-block flex-column">
                                        {confirmPasswordCheck || !pdf.secure ? <> 
                                            <MDBBtn color="primary" onClick={this.handleSavePDF} > <FormattedMessage id="ups.preferences.notifications.btn-save" /> </MDBBtn>
                                            {pdf.secure ?<MDBBtn color="cancel" onClick={this.hideConfirmPassword}><FormattedMessage id={'cancel.label'} /></MDBBtn>:''}</>
                                            :
                                            <MDBBtn color="primary" onClick={this.showConfirmPassword} >
                                                {pdf.password ?
                                                    <FormattedMessage id="ups.preferences.pdf-invoice.btn-change-password" />
                                                    : 
                                                    <FormattedMessage id="ups.preferences.notifications.btn-save" />
                                                }
                                            </MDBBtn>}
                                        </MDBCol>
                                    </MDBRow>}
                            </MDBCardBody>
                        </MDBCard>

                    </MDBCol>
                    {saveButtonOpenModal ?
                        <MDBRow>
                            <MDBCol>
                                <SaveModal
                                    key={'SaveButton-modal'}
                                    isOpen={saveButtonOpenModal}
                                    closeModal={this.saveButtonCloseModal}
                                    component={saveButtonModalComp}
                                    modalData={saveModalData}
                                    modalHeadigKey={saveModalHeading}
                                    modalHeadingCssClass={modalHeadingCssClass}
                                />
                            </MDBCol>
                        </MDBRow>
                        :
                        null
                    }
                    {(isPageDirty || pdf.password != pdf.reenterPassword) &&
                    <PreferencesPrompt
                        preparePreferences = {this.prepareSecurePdfPreferences}
                    /> }
                </MDBRow>
                {showErrorMessage && <NotificationModal isOpen={showErrorMessage} title=" " size="md" closeModal={() => { this.setState(this.getIntialState()) }} buttonTypes={BUTTON_TYPES.OK} message={errorCode ?? "internalError"} />}
            </MDBContainer>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        vFields: state.validation.vFields,
        vState: state.validation.vState,
        auth: state.auth,
        merchants: state.auth.merchants,
        disableSecurePdf: formatBool(state.envProps.disableSecurePdf),
        preferences: state.identity.preferences
    };
}

function mapDispatchToProps(dispatch) {
    return {
        identityActions: bindActionCreators(identityActions, dispatch),
        validationActions: bindActionCreators(validationActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(SecurePDFPreferences)));