import { v4 as uuidv4 } from 'uuid';
import * as R           from 'ramda';
import store            from '../index';
import { CSRF_TOKEN }   from '../constants/action-types';
import {pick as _pick} from "lodash"
import $ from 'jquery';

import { sendGoogleAnalyticsEvent as gaEvent, sendGoogleAnalyticsTiming as gaTiming, GA_CATEGORY } from "./google-analytics-utils"

const { REACT_APP_API_URL_PREFIX } = process.env;

const instanceId = uuidv4();
const path = require('path');

const defaultRequestHeaders =
    { 'Content-Type': 'application/json'
    , 'Accept':       'application/json'
    , 'instance-id': instanceId
    };

const getCsrfToken = res => res.headers.get('x-csrf-token');

const storeCsrfToken = R.compose
  ( R.when
      ( R.complement(R.isNil)
      , token => store.dispatch({type: CSRF_TOKEN, token})
      )
  , getCsrfToken
  );


const getStateCsrfToken = () => store?.getState().auth.csrfToken;

function getStateExternalUserId () {
  // The auth structure is taking different forms for different
  // scenarios. Why?? Unfortunately we need to look up all
  // the possiblities when sourcing the external id.
  const path1 = ['auth', 'user', 'externalId']; // normal logon
  const path2 = ['auth', 'userInfo', 'externalId']; // not sure?
  const path3 = ['auth', 'userInfo', 'userId']; // when it is enrolment
  const findExternalId = R.cond
    ( [ [R.isNil, R.always('')]
      , [R.hasPath(path1), R.path(path1)]
      , [R.hasPath(path2), R.path(path2)]
      , [R.hasPath(path3), R.path(path3)]
      , [R.T, R.always('')]
      ]
    );
  return findExternalId(store?.getState())
}

async function fetchWithGoogleAnalytics(url, options) {
  const startTimer = new Date().getTime();
  const res = await fetch(url, options);
  const endTimer = new Date().getTime();
  const endpoint = url.split("?")[0];
  gaTiming(GA_CATEGORY.API, `${options.method} ${endpoint} response time`, endTimer - startTimer)
  gaEvent(GA_CATEGORY.API, `${options.method} ${endpoint} status code`, 1, res.status)

  return res;
}

function generateFetchOptions (method, headers, body, extraOptions) {
    const csrfToken = getStateCsrfToken();
    const xSubToken = getStateExternalUserId();
    let options = { credentials: 'same-origin'
                  , method
                  , headers: { ...defaultRequestHeaders
                             , ...headers
                             , ...(csrfToken ? ({'x-csrf-token': csrfToken}) : {})
                             , ...(xSubToken ? ({'x-sub-token': xSubToken}) : {})
                             }
                  , ...(extraOptions ? extraOptions : {})
                  , redirect: 'error'
                  };

    if (body) {
        options.body = JSON.stringify(body);
    }
    return options
}

export async function fetchWrapper(url, method, headers = {}, body, extraOptions) {
    let options = generateFetchOptions(method, headers, body, extraOptions)
    const res = await fetchWithGoogleAnalytics(url, options);
    storeCsrfToken(res);
    try {
        res.parsedBody = await res.json();
    } catch (err) {
        // ignore
    }

    if (!res.ok) {
        // eslint-disable-next-line no-throw-literal
        throw {
            errorCode: res.parsedBody?.errorCode ?? 'missing.server.error.label',
            status: res.status,
            statusText: res.statusText,
            completeError: res.parsedBody
        }
    }

    if (res.parsedBody?.redirect) {
        window.location.href = res.parsedBody.redirectUrl;
    }
    return res;
}

export async function fetchDownloadWrapper(url, method, headers, body) {
    let options = generateFetchOptions(method, headers, body);
    if (body) {
        options.body = JSON.stringify(body);
    }

    try {
        const response = await fetchWithGoogleAnalytics(url, options);
        await processDownload(response)
    } catch (err) {
        console.error("download failed", err);
        throw err;
    }
}

export function getCountries(merchants) {
    return merchants.map(m => m.countryCode);
}

export function countryCodeToMerchantId(merchants, countryCode) {
    return merchants.find(m => m.countryCode === countryCode)?.id;
}

export function getQueryParams(qs, replacePlus = true) {
    qs = replacePlus ? qs.split('+').join(' ') : qs;
    var params = {},
        tokens,
        re = /[?&]?([^=]+)=([^&]*)/g;
    while (true) {
        tokens = re.exec(qs);
        if (!tokens) {
            break;
        }       
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }
    return params;
}

export const encodeText64 = str => Buffer.from(str, 'utf-8').toString('base64');
export const decodeText64 = b64 => Buffer.from(b64, 'base64').toString('utf-8');

export function languageFormatter(lang) {
    let language = lang;
    if (language && typeof language != 'string') {
        language = language.toString();
    }
    switch (language) {
        case 'en-US':
        case 'en_US':
        case 'en_CA':
        case 'en_GB':
        case 'EN_US':
        case 'EN_CA':
        case 'EN_GB':
            return 'English';
        case 'es_US':
        case 'ES_US':
        case 'en_ES':
        case 'es':
            return 'Spanish';
        case 'en_FR':
        case 'fr_CA':
        case 'fr_FR':
        case 'FR_CA':
        case 'FR_FR':
        case 'fr':
            return 'French';
        case 'pt':
            return 'Português';
        case 'zn-CN':
            return '简体中文'; // chinese (prc)
        case 'ja':
            return '日本語'; // japanese
        default:
            return null;
    }
}

//map between frontend (capitalized) role format and backend (unformatted) role format
const roleMap = {
    "Administrator": "ADMINISTRATOR",
    "ADMINISTRATOR": "Administrator",
    "View, Pay and Dispute": "VIEW_PAY_DISPUTE",
    "VIEW_PAY_DISPUTE": "View, Pay and Dispute",
    "View Only": "VIEW_ONLY",
    "VIEW_ONLY": "View Only",
    "Plan Manager": "PLAN_MANAGER",
    "PLAN_MANAGER": "Plan Manager",
    "Manager" : "Manager"
}

export function mapRole(role) {
    return R.prop(role)(roleMap);
}

export function nameToString(firstName, lastName, middleName){
    return `${firstName ? (firstName + ' ') : ''}${middleName ? (middleName + ' ') : ''}${(lastName === '-') ? '' : (lastName ?? '')}`
}

export function maskNumber(str) {
    return (str || '').replace(/\d(?=\d{4})/g, "*");
}

export function maskIban(str) {
    return str && `${str.slice(0, 2)}****${str.slice(-4)}`;
}

export function maskString(str) {
    return str && `****${str.slice(-4)}`;
}

export function maskStringFullLength(str) {
    let maskedString = []
    for(let x = 0; x < str.length - 4; x++) maskedString.push("*")
    return str && `${maskedString.join('')}${str.slice(-4)}`;
}

export function shortUid () {
    return '_' + Math.random().toString(36).substr(2, 9);
}

export const overIf = R.curry((lens, fn) =>
    R.unless
        ( R.o(R.isNil, R.view(lens))
        , R.over(lens, fn)
        )
    );

export const toUrlNvps = R.compose
  ( R.join('&') // monoid concat
  , R.map( R.ifElse
              ( R.compose(R.isNil, R.last)
              , R.always ('')
              , ([k, v]) => `${k}=${v}`
              )
         )
  , R.toPairs
  );

export const getFullUrl = R.concat(REACT_APP_API_URL_PREFIX);

const hasJson = R.compose
    ( R.startsWith('application/json')
    , ({headers}) => headers.get('content-type') ?? ''
    );

const extractBody = R.ifElse
    ( hasJson
    , r  => r.json()
    , () => Promise.resolve([])
    );

const checkStatus = R.ifElse
    ( R.prop('ok')
    , extractBody
    , R.compose
        ( R.andThen(d => Promise.reject(d))
        , R.andThen
            ( R.when
                ( R.anyPass([R.isEmpty, R.complement(R.has('errorCode'))])
                , R.always({errorCode: 'page.unexpectedError'})
                )
            )
        , extractBody
        )
    );

const checkRedirect = R.when
    ( R.prop('redirect')
    , ({redirectUrl}) => window.location = redirectUrl
    );

const addCsrfTokenHeader = h =>
  ( R.when
      ( R.complement(R.isNil)
      , token => ({...h, 'x-csrf-token': token})
      )
  )
  (getStateCsrfToken());

const addCsrfToken = R.over(R.lensProp('headers'), addCsrfTokenHeader);

const callApi = method => R.compose
    ( R.andThen(checkRedirect)
    , R.andThen(checkStatus)
    , R.andThen(R.tap(storeCsrfToken))
    , ({url, body, headers}) => fetchWithGoogleAnalytics(url, generateFetchOptions (method, headers, body))
    , addCsrfToken
    // , overIf(R.lensProp('body'), b => JSON.stringify(b))
    );

export const callApiDownload = method => R.compose
    ( R.andThen(processDownload)
    , R.andThen(R.tap(storeCsrfToken))
    , ({url, body, headers}) => fetchWithGoogleAnalytics(url, generateFetchOptions (method, headers, body))
    , addCsrfToken
);

export const uniquePropVals = (array, prop)=>{
   //For an array of objects, return an array of unique values for the given object property
   return uniquePropValsGetter(array,(obj)=>obj[prop])
}

export const uniquePropValsGetter = (array, getter, toKey)=>{
    let unique = {}
    array.forEach((obj)=>{
        let val = getter(obj)
        unique[toKey?.(obj) ?? val] = val
    })
    return Object.values(unique)
}

export const callApiGet     = callApi ('GET');
export const callApiPost    = callApi ('POST');
export const callApiPut     = callApi ('PUT');
export const callApiDelete  = callApi ('DELETE');

export const formatBool = (value) => ["true", true, 1, '1', 'y', 'yes'].includes(typeof value==='string' ? value.toLowerCase() : value)

// order can be set in languages.json or standard-formats.json if the country/language has no language.json
// "country-item.COUNTRY_CODE.order": "ORDER_VALUE", e.g. "country-item.CA.order": "1"
// this order will be applied to the enrollment country list and the country / business unit panel
export const generateCountryList = (array, countryData, intl) => array.map(country => {
  const countryObj = countryData ? countryData.find(c => c.code === country) : null;
  if (countryObj) {
      // Only return options that are found inside countryData, if country is not added properly then need to update countryData file
      return {
          "value": countryObj.code,
          "msgId": "country-item." + countryObj.code,
          "order": !!intl.messages[`country-item.${countryObj.code}.order`] ? parseInt(intl.messages[`country-item.${countryObj.code}.order`]) : 999,
          "sortName": intl.formatMessage({ id: `country-item.${countryObj.code}`})
      };
  } else {
      return {
          ...country,
          "order": !!intl.messages[`country-item.${country.country}.order`] ? parseInt(intl.messages[`country-item.${country.country}.order`]) : 999,
          "sortName": intl.formatMessage({ id: `country-language-item.${country.id}`})
      };
  }
});

export const sortCountryList = (array) => array.sort(function(a, b) {
  if (a.order > b.order) return 1;
  else if (a.order < b.order) return -1;
  else return (new Intl.Collator().compare(a.sortName, b.sortName));
});

export const createLookupTable = (array, keyFunc, valFunc) => {
    return array?.reduce((acc, arrayValue)=>{
        acc[keyFunc ? keyFunc(arrayValue) : arrayValue] = valFunc ? valFunc(arrayValue) : true
        return acc;
    }, {}) ?? {}
}

const onKey = (keyCode, name, action, e) => {
    if(e.keyCode === keyCode || e.which === keyCode || e.code === name) action(e)
}

export const upsHelpUrlFormatter = (country, language) => {
    const languageMap = {
        'lu': {
            'de': 'fr',
        },
        'ch': {
            'it': 'fr',
        }
    };
    const newLanguage = languageMap[country]?.[language] || language;
    return path.join(country, newLanguage);
}

export const onSpaceBar = (action) => (e) => onKey(32, 'Space', action, e)
export const onEnterPress = (action) => (e) => onKey(13, 'Enter', action, e)
export const onUpArrow = (action) => (e) => onKey(38, 'ArrowUp', action, e)
export const onDownArrow = (action) => (e) => onKey(40, 'ArrowDown', action, e)
export const onTab = (action) => (e) => onKey(9, 'Tab', action, e)

export const doFocus = (id) => id && $(`${id}`).focus()

const getSessionFilterValue = (defaultValue = {}) => {
    try{
        return JSON.parse(sessionStorage.getItem("sessionFilters")) ?? defaultValue
    } catch(e) {
        return defaultValue
    }
}

const getSessionFilterID = (filterId, businessUnit, country) => {
    if(businessUnit && country) return `${country}-${businessUnit}-${filterId}`
    else return filterId
}

export const getSessionFilter = (filterId, businessUnit, country) => {
    let sessionFilters = getSessionFilterValue()
    return sessionFilters[getSessionFilterID(filterId,businessUnit,country)]
}

export const clearSessionFilter = (filterId, businessUnit, country) => {
    let sessionFilters = getSessionFilterValue()
    if(sessionFilters) delete sessionFilters[getSessionFilterID(filterId,businessUnit,country)]
    sessionStorage.setItem("sessionFilters", JSON.stringify(sessionFilters));
}

export const partiallyClearSessionFilter = (filterId, businessUnit, country, valuesToLeave) => {
    let sessionFilters = getSessionFilterValue()
    const fullFilterId = getSessionFilterID(filterId,businessUnit,country)
    if(sessionFilters) sessionFilters[fullFilterId] = _pick(sessionFilters[fullFilterId] ?? {}, valuesToLeave)
    sessionStorage.setItem("sessionFilters", JSON.stringify(sessionFilters));
}

export const setSessionFilter = (filterId, filter, businessUnit, country) => {
    let sessionFilters = getSessionFilterValue()
    sessionFilters[getSessionFilterID(filterId,businessUnit,country)] = filter
    sessionStorage.setItem("sessionFilters", JSON.stringify(sessionFilters));
}

export const processDownload = async (response) => {
    if (!response.ok) {
        const parsedBody = await response.json();
        // eslint-disable-next-line no-throw-literal
        throw {
            status: response.status,
            statusText: response.statusText,
            parsedBody
        };
    }

    const blob = await response.blob();
    const contentDisposition = response.headers.get('content-disposition') || '';
    const fileName = contentDisposition.includes('filename=')
        ? contentDisposition.split('filename=')[1]
        : uuidv4();

    const contentType = response.headers.get('content-type');

    if (contentType.toLowerCase() === 'application/pdf') {
        window.open(URL.createObjectURL(blob));
    } else {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            const file = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = file;
            a.download = fileName;

            const clickHandler = () => {
                setTimeout(() => {
                    window.URL.revokeObjectURL(file);
                    this.removeEventListener('click', clickHandler);
                }, 150);
            }

            a.addEventListener('click', () => clickHandler, false);
            a.click();
        }
    }
    return response
}